import { ENDPOINT } from './enums/EndPoint';
import ApiServices from './ApiServices';

/**
 * Gera um boleto em lote
 */
export function generateBatch(params) {
  return ApiServices.post(ENDPOINT.BOLETO_BATCH, params);
}

/**
 * Busca os boletos gerados
 * @param {number} page
 */
export function getAllBoletos() {
  return ApiServices.get(ENDPOINT.BOLETO_BATCH);
}

const PaymentServices = {
  generateBatch,
  getAllBoletos,
};
export default PaymentServices;
