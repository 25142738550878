import React, { useState } from "react";
import { PhysicalDocumentForm } from "../PhysicalPerson";
import { LegalDocumentForm } from "../LegalPerson";
import { connect } from "react-redux";
import DocumentSuccess from "./DocumentSuccess";
import DocumentMain from "./DocumentMain";;

function Main(props) {
    const { isSend, personType } = props;
    const [sendDocument, setBoolean] = useState(false);
    const [load, setLoad] = useState(false);
    
    return (
        <div className="row">
            {(() => {
                if(isSend) {
                    return <DocumentSuccess />
                }

                if(!sendDocument && props.files === "") {
                    return (<DocumentMain goToDocuments={setBoolean} load={load} />)
                }
                
                if(personType === 'physical_type') { 
                    return <PhysicalDocumentForm setBoolean={setLoad}/>; 
                }
                return <LegalDocumentForm setBoolean={setLoad} />;
            })()}
        </div>
    );
}

export default connect(
	({ register }) => ({ 
        personType: register.loadType,
        files: register.file_document_front,
        isSend: (Object.keys(register.documents).length > 0) ? (true) : (false)
}), null)(Main)