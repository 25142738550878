import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    listLoading: false,
    actionsLoading: false,
    meta: 0,
    entities: [],
    keyData: [],
    pagination: [],
    error: null,
    operation: [],
    error_fields: ""
}

export const callTypes = {
    list: "list",
    action: "action"
};

export const pixSlice = createSlice({
    name: "internal",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.err.clientMessage}`;
            
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        internalTransferFetched: (state, action) => {  
            const { pagination, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.pagination = pagination;
            state.operation = [];
        },
        fetchTransfer: (state, action) => {
            state.listLoading = false;
            state.operation = action.payload;
        },
        fetchKeyData: (state, action) => {
            state.listLoading = false;
            state.keyData = action.payload;
        },
        fetchError: (state, action) => {
            state.listLoading = false;
            state.error_fields = action.payload;
            state.operation = [];
        }
    }
});