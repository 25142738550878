export const initialState = {
    initialized: false,
    basic: {},
    personal: {},
    financial: {}
}

export const types = {
    BasicRegister: "[Register] Basic Register",
    BasicRegisterLoaded: "[Loaded] Basic Register",
    PersonalRegister: "[Register] Personal Register",
    PersonalRegisterLoaded: "[Loaded] Personal Register",
    FinancialRegister: "[Register] Financial Register",
    FinancialRegisterLoaded: "[Loaded] Financial Register",
    ClearPhysical: "[Clear] Physical"
}

export const actions = {
    registerBasicInformation: register => ({ 
        type: types.BasicRegister, 
        payload: { register } 
    }),
    loadBasicInformation: register => ({ 
        type: types.BasicRegisterLoaded, 
        payload: { register } 
    }),
    registerPersonalInformation: register => ({ 
        type: types.PersonalRegister, 
        payload: { register } 
    }),
    loadPersonalInformation: register => ({ 
        type: types.PersonalRegisterLoaded, 
        payload: { register } 
    }),
    registerFinancialInformation: register => ({ 
        type: types.FinancialRegister, 
        payload: { register } 
    }),
    loadFinancialInformation: register => ({ 
        type: types.FinancialRegisterLoaded, 
        payload: { register } 
    }),
    clearPhysicalPerson: register => ({ 
        type: types.ClearPhysical, 
        payload: { register } 
    })
}