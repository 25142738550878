import { ENDPOINT } from './enums/EndPoint';
import ApiServices from './ApiServices';

/**
 * Faz uma transferência PIX
 * @param {string} email
 * @param {string} password
 * @return Promise
 */
const makePixTransfer = params => {
  var suffix = params.type === 'phone' ? '+55' : '';
  return ApiServices.post(ENDPOINT.PIX_TRANSFERS, {
    value: params.value,
    destination_pix_identifier: suffix + params.destination_pix_identifier,
    description: params.description,
    is_nominal: params.is_nominal,
  });
};

/**
 * Get key data by value
 * @return Promise
 */
const getPixKeyData = params => {
  var suffix = params.type === 'phone' ? '+55' : '';
  return ApiServices.post(ENDPOINT.PIX_FETCH_KEY, {
    key: suffix + params.key,
    document: params.document,
  });
};

// /**
//  * Get key data by value
//  * @return Promise
//  */
// const getPixKeyData = ({ key }) => {
//   return ApiServices.get(`${ENDPOINT.PIX_FETCH_KEY}/${key}`);
// };

const PixServices = {
  makePixTransfer,
  getPixKeyData,
};

export default PixServices;
