/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { checkIsActive } from '../../../../helpers';
import { useSelector } from 'react-redux';
import { NavLinkCustom } from '../../NavLinkCustom/NavLinkCustom';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { modules, financial } = useSelector(state => {
    return {
      modules: state.settings.modules,
      financial: state.auth.financial,
    };
  });

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu} menu-item-open `
      : '';
  };

  const getMenuItemActiveCss = url => {
    return checkIsActive(location, url)
      ? layoutProps.colors.secondary
      : layoutProps.colors.primary;
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::Dashboard*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
          aria-haspopup="true"
        >
          <NavLinkCustom
            layoutProps={layoutProps}
            page="/dashboard"
            title="MENU.DASHBOARD"
            svg="/media/svg/icons/Design/Layers.svg"
          />
        </li>
        {/*end::Dashboard*/}

        {(() => {
          // Verify if documents was approved
          if (financial.status === 'approved') {
            return (
              <>
                {/*begin::My_Account*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    '/conta',
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLinkCustom
                    layoutProps={layoutProps}
                    page="/conta"
                    title="MENU.MY_ACCOUNT"
                    svg="/media/svg/icons/Home/Library.svg"
                    style={{
                      backgroundColor: `${getMenuItemActiveCss('/conta')}`,
                    }}
                  >
                    <i className="menu-arrow" />
                  </NavLinkCustom>

                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text">
                            <FormattedMessage id="MENU.MY_ACCOUNT" />
                          </span>
                        </span>
                      </li>

                      {/*begin::2 Level*/}
                      {(() => {
                        if (modules.MODULE_EXTRACT) {
                          return (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                '/conta/resumo'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/conta/resumo'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/conta/resumo"
                                title="MENU.MY_ACCOUNT.RESUME"
                                svg="/media/svg/icons/Shopping/Chart-line2.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/conta/resumo'
                                  )}`,
                                }}
                              />
                            </li>
                          );
                        }
                      })()}
                      {/*end::2 Level*/}

                      {/*begin::2 Level*/}
                      {(() => {
                        if (modules.MODULE_EXTRACT) {
                          return (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                '/conta/extrato'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/conta/extrato'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/conta/extrato"
                                title="MENU.MY_ACCOUNT.EXTRACT"
                                svg="/media/svg/icons/Files/File.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/conta/extrato'
                                  )}`,
                                }}
                              />
                            </li>
                          );
                        }
                      })()}
                      {/*end::2 Level*/}

                      {/*begin::3 Level*/}
                      {(() => {
                        if (modules.MODULE_EXTRACT) {
                          return (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                '/pagamento/automatico'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/pagamento/automatico'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/pagamento/automatico"
                                title="MENU.MY_ACCOUNT.AUTOMATIC_DEBIT"
                                svg="/media/svg/icons/Files/File.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/pagamento/automatico'
                                  )}`,
                                }}
                              />
                            </li>
                          );
                        }
                      })()}
                      {/*end::3 Level*/}

                      {/*begin::3 Level*/}
                      {(() => {
                        return (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              '/conta/limits'
                            )}`}
                            style={{
                              backgroundColor: `${getMenuItemActiveCss(
                                '/conta/limits'
                              )}`,
                            }}
                            aria-haspopup="true"
                          >
                            <NavLinkCustom
                              layoutProps={layoutProps}
                              page="/conta/limits"
                              title="MENU.MY_ACCOUNT.LIMITS"
                              svg="/media/svg/icons/Files/File.svg"
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/conta/limits'
                                )}`,
                              }}
                            />
                          </li>
                        );
                      })()}
                      {/*end::3 Level*/}
                      {/*begin::3 Level*/}
                      {(() => {
                        return (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              '/conta/favoritos'
                            )}`}
                            style={{
                              backgroundColor: `${getMenuItemActiveCss(
                                '/conta/favoritos'
                              )}`,
                            }}
                            aria-haspopup="true"
                          >
                            <NavLinkCustom
                              layoutProps={layoutProps}
                              page="/conta/favoritos"
                              title="MENU.MY_ACCOUNT.FAVORITES"
                              svg="/media/svg/icons/Files/File.svg"
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/conta/favoritos'
                                )}`,
                              }}
                            />
                          </li>
                        );
                      })()}
                      {/*end::3 Level*/}

                      {/*begin::4 Level*/}
                      {(() => {
                        if (
                          modules.MODULE_DEPOSIT_BOLETO ||
                          modules.MODULE_DEPOSIT_TED
                        ) {
                          return (
                            <li
                              className={`menu-item menu-item-submenu ${getMenuItemActive(
                                '/conta/deposito',
                                true
                              )}`}
                              aria-haspopup="true"
                              data-menu-toggle="hover"
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/conta/deposito'
                                )}`,
                              }}
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/conta/deposito"
                                title="MENU.MY_ACCOUNT.DEPOSIT"
                                svg="/media/svg/icons/Shopping/Money.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/conta/deposito'
                                  )}`,
                                }}
                              >
                                <i className="menu-arrow" />
                              </NavLinkCustom>
                              <div className="menu-submenu ">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  {/*begin::1 Level*/}
                                  <li
                                    className={`menu-item  ${getMenuItemActive(
                                      '/conta/deposito/boleto'
                                    )}`}
                                    aria-haspopup="true"
                                    style={{
                                      backgroundColor: `${getMenuItemActiveCss(
                                        '/conta/deposito/boleto'
                                      )}`,
                                    }}
                                  >
                                    {modules.MODULE_DEPOSIT_BOLETO && (
                                      <NavLinkCustom
                                        layoutProps={layoutProps}
                                        page="/conta/deposito/boleto"
                                        title="MENU.MY_ACCOUNT.DEPOSIT.BILL"
                                        svg="/media/svg/icons/Shopping/Barcode.svg"
                                        style={{
                                          backgroundColor: `${getMenuItemActiveCss(
                                            '/conta/deposito/boleto'
                                          )}`,
                                        }}
                                      />
                                    )}

                                    {modules.MODULE_DEPOSIT_TED && (
                                      <NavLinkCustom
                                        layoutProps={layoutProps}
                                        page="/conta/deposito/ted"
                                        title="MENU.MY_ACCOUNT.DEPOSIT.TED"
                                        svg="/media/svg/icons/Shopping/Barcode.svg"
                                        style={{
                                          backgroundColor: `${getMenuItemActiveCss(
                                            '/conta/deposito/ted'
                                          )}`,
                                        }}
                                      />
                                    )}
                                  </li>
                                  {/*end::1 Level*/}

                                  {/* <li
                                    className={`menu-item  ${getMenuItemActive(
                                      '/conta/deposito/boleto'
                                    )}`}
                                    aria-haspopup="true"
                                    style={{
                                      backgroundColor: `${getMenuItemActiveCss(
                                        '/conta/deposito/boleto'
                                      )}`,
                                    }}
                                  >
                                    <NavLinkCustom
                                      layoutProps={layoutProps}
                                      page="/conta/deposito/boleto"
                                      title="MENU.MY_ACCOUNT.DEPOSIT.BILL.BATCH"
                                      svg="/media/svg/icons/Shopping/Barcode.svg"
                                      style={{
                                        backgroundColor: `${getMenuItemActiveCss(
                                          '/conta/deposito/boleto'
                                        )}`,
                                      }}
                                    />
                                  </li> */}
                                </ul>
                              </div>
                            </li>
                          );
                        }
                      })()}

                      {/*end::4 Level*/}
                    </ul>
                  </div>
                </li>
                {/*end::My_Account*/}

                {/* Transferências */}
                {(() => {
                  if (
                    modules.MODULE_BANK_TRANSFER &&
                    financial.status === 'approved'
                  ) {
                    return (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          '/transferencia',
                          true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        style={{
                          backgroundColor: `${getMenuItemActiveCss(
                            '/transferencia'
                          )}`,
                        }}
                      >
                        <NavLinkCustom
                          layoutProps={layoutProps}
                          page="/transferencia"
                          title="MENU.TRANSFER"
                          svg="/media/svg/icons/Navigation/Exchange.svg"
                          style={{
                            backgroundColor: `${getMenuItemActiveCss(
                              '/transferencia'
                            )}`,
                          }}
                        >
                          <i className="menu-arrow" />
                        </NavLinkCustom>
                        <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className="menu-item  menu-item-parent"
                              aria-haspopup="true"
                            >
                              <span className="menu-link">
                                <span className="menu-text">
                                  <FormattedMessage id="MENU.TRANSFER" />
                                </span>
                              </span>
                            </li>

                            {/*begin::2 Level*/}
                            <li
                              className={`menu-item ${getMenuItemActive(
                                '/transferencia/ted'
                              )}`}
                              aria-haspopup="true"
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/transferencia/ted'
                                )}`,
                              }}
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/transferencia/ted"
                                title="MENU.TRANSFER.EXTERNAL"
                                svg="/media/svg/icons/Navigation/Exchange.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/transferencia/ted'
                                  )}`,
                                }}
                              />
                            </li>
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            <li
                              className={`menu-item ${getMenuItemActive(
                                '/transferencia/tinterna'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/transferencia/interna'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/transferencia/interna"
                                title="MENU.TRANSFER.INTERNAL"
                                svg="/media/svg/icons/General/Update.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/transferencia/interna'
                                  )}`,
                                }}
                              />
                            </li>
                            {/*end::2 Level*/}

                            {/*begin::2 Level*/}
                            {/* <li
                              className={`menu-item ${getMenuItemActive(
                                '/transferencia/ted'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/transferencia/ted'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/transferencia/ted"
                                title="MENU.TRANSFER.BATCH"
                                svg="/media/svg/icons/General/Update.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/transferencia/ted'
                                  )}`,
                                }}
                              />
                            </li> */}
                            {/*end::2 Level*/}
                          </ul>
                        </div>
                      </li>
                    );
                  }
                })()}
                {/*end::1 Level*/}

                {/* Payment */}
                {(() => {
                  if (
                    modules.MODULE_PAYMENTS &&
                    financial.status === 'approved'
                  ) {
                    return (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          '/pagamento',
                          true
                        )}`}
                        style={{
                          backgroundColor: `${getMenuItemActiveCss(
                            '/pagamento'
                          )}`,
                        }}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        <NavLinkCustom
                          layoutProps={layoutProps}
                          page="/pagamento"
                          title="MENU.PAYMENT"
                          svg="/media/svg/icons/Shopping/Wallet.svg"
                          style={{
                            backgroundColor: `${getMenuItemActiveCss(
                              '/pagamento'
                            )}`,
                          }}
                        >
                          <i className="menu-arrow" />
                        </NavLinkCustom>
                        <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className="menu-item  menu-item-parent"
                              aria-haspopup="true"
                            >
                              <span className="menu-link">
                                <span className="menu-text">
                                  <FormattedMessage id="MENU.PAYMENT" />
                                </span>
                              </span>
                            </li>

                            {/* Surfaces */}
                            {/*begin::2 Level*/}
                            <li
                              className={`menu-item ${getMenuItemActive(
                                '/pagamento/boleto'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/pagamento/boleto'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/pagamento/boleto"
                                title="MENU.PAYMENT.BILL"
                                svg="/media/svg/icons/Shopping/Barcode.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/pagamento/boleto'
                                  )}`,
                                }}
                              />
                            </li>

                            {/* <li
                              className={`menu-item ${getMenuItemActive(
                                '/pagamento/boleto'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/pagamento/boleto'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/pagamento/boleto"
                                title="MENU.PAYMENT.BILL.BATCH"
                                svg="/media/svg/icons/Shopping/Barcode.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/pagamento/boleto'
                                  )}`,
                                }}
                              />
                            </li> */}

                            {/*begin::2 Level*/}
                            <li
                              className={`menu-item ${getMenuItemActive(
                                '/pagamento/consumo'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/pagamento/consumo'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/pagamento/consumo"
                                title="MENU.PAYMENT.CONSUMER"
                                svg="/media/svg/icons/Text/Bullet-list.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/pagamento/consumo'
                                  )}`,
                                }}
                              />
                            </li>
                            {/*end::2 Level*/}
                          </ul>
                        </div>
                      </li>
                    );
                  }
                })()}
                {/*end::1 Level*/}

                {/* Cartões */}
                {
                  // (() => {
                  // if (modules.MODULE_CARDS) {
                  //   return (
                  //     <li
                  //       className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //         "/cartao", true
                  //       )}`}
                  //       style={{ backgroundColor: `${getMenuItemActiveCss("/cartao")}` }}
                  //       aria-haspopup="true"
                  //       data-menu-toggle="hover"
                  //     >
                  //       <NavLinkCustom
                  //         layoutProps={layoutProps}
                  //         page="/cartao"
                  //         title="MENU.CARD"
                  //         svg="/media/svg/icons/Shopping/Credit-card.svg"
                  //         style={{ backgroundColor: `${getMenuItemActiveCss("/cartao")}`}}
                  //       >
                  //         <i className="menu-arrow" />
                  //       </NavLinkCustom>
                  //       <div className="menu-submenu ">
                  //         <i className="menu-arrow" />
                  //         <ul className="menu-subnav">
                  //           <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  //             <span className="menu-link">
                  //               <span className="menu-text">
                  //                 <FormattedMessage
                  //                   id="MENU.CARD"
                  //                 />
                  //               </span>
                  //             </span>
                  //           </li>
                  //           {/*begin::2 Level*/}
                  //           <li
                  //             className={`menu-item ${getMenuItemActive("/cartao")}`}
                  //             style={{ backgroundColor: `${getMenuItemActiveCss("/cartao")}` }}
                  //             aria-haspopup="true"
                  //           >
                  //             <NavLinkCustom
                  //               layoutProps={layoutProps}
                  //               page="/cartao"
                  //               title="MENU.CARD.SETTINGS"
                  //               svg="/media/svg/icons/Shopping/Box1.svg"
                  //               style={{ backgroundColor: `${getMenuItemActiveCss("/cartao")}`}}
                  //             />
                  //           </li>
                  //           {/*begin::2 Level*/}
                  //           <li
                  //             className={`menu-item ${getMenuItemActive("/cartao")}`}
                  //             style={{ backgroundColor: `${getMenuItemActiveCss("/cartao")}` }}
                  //             aria-haspopup="true"
                  //           >
                  //             <NavLinkCustom
                  //               layoutProps={layoutProps}
                  //               page="/cartao"
                  //               title="MENU.CARD.NEW"
                  //               svg="/media/svg/icons/Shopping/Credit-card.svg"
                  //               style={{ backgroundColor: `${getMenuItemActiveCss("/cartao")}`}}
                  //             />
                  //           </li>
                  //           {/*end::2 Level*/}
                  //         </ul>
                  //       </div>
                  //     </li>
                  //   )
                  // }
                  // })()
                }
                {/*end::1 Level*/}

                {(() => {
                  if (modules.MODULE_PIX && financial.status === 'approved') {
                    return (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          '/pix',
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLinkCustom
                          layoutProps={layoutProps}
                          page="/conta/pix"
                          title="PIX"
                          svg="/media/svg/icons/Design/Layers.svg"
                        />
                      </li>
                    );
                  }
                })()}

                {/* Outros Servicos */}
                {(() => {
                  if (
                    modules.MODULE_PHONE_RECHARGE &&
                    financial.status === 'approved'
                  ) {
                    return (
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          '/servicos',
                          true
                        )}`}
                        style={{
                          backgroundColor: `${getMenuItemActiveCss(
                            '/servicos'
                          )}`,
                        }}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        <NavLinkCustom
                          layoutProps={layoutProps}
                          page="/servicos"
                          title="MENU.OTHER_SERVICE"
                          svg="/media/svg/icons/Code/Plus.svg"
                          style={{
                            backgroundColor: `${getMenuItemActiveCss(
                              '/servicos'
                            )}`,
                          }}
                        >
                          <i className="menu-arrow" />
                        </NavLinkCustom>
                        <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className="menu-item menu-item-parent"
                              aria-haspopup="true"
                            >
                              <span className="menu-link">
                                <span className="menu-text">
                                  <FormattedMessage id="MENU.OTHER_SERVICE" />
                                </span>
                              </span>
                            </li>
                            {/*begin::2 Level*/}
                            <li
                              className={`menu-item ${getMenuItemActive(
                                '/servicos/recarga-celular'
                              )}`}
                              style={{
                                backgroundColor: `${getMenuItemActiveCss(
                                  '/servicos/recarga-celular'
                                )}`,
                              }}
                              aria-haspopup="true"
                            >
                              <NavLinkCustom
                                layoutProps={layoutProps}
                                page="/servicos/recarga-celular"
                                title="MENU.OTHER_SERVICE.PHONE_RECHARGE"
                                svg="/media/svg/icons/Devices/Phone.svg"
                                style={{
                                  backgroundColor: `${getMenuItemActiveCss(
                                    '/servicos/recarga-celular'
                                  )}`,
                                }}
                              />
                            </li>
                            {/*end::2 Level*/}
                          </ul>
                        </div>
                      </li>
                    );
                  }
                })()}
                {/*end::1 Level*/}
              </>
            );
          }
        })()}
        <li
          className={`menu-item ${getMenuItemActive('/suporte', false)}`}
          aria-haspopup="true"
        >
          <NavLinkCustom
            layoutProps={layoutProps}
            page="/conta/suporte"
            title="MENU.SUPPORT"
            svg="/media/svg/icons/Design/Layers.svg"
          />
        </li>
        {modules.MODULE_BOLETO_BATCH && financial.status === 'approved' && (
          <li
            className={`menu-item ${getMenuItemActive(
              '/conta/depositolote',
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLinkCustom
              layoutProps={layoutProps}
              page="/conta/depositolote"
              title="MENU.DEPOSITBATCH"
              svg="/media/svg/icons/Design/Layers.svg"
            />
          </li>
        )}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
