import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import * as deposit from "../../_redux/deposit/depositRedux";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../../helpers";
import * as uiHelpers from "../DepositUIHelpers";
import * as columnFormatters from "./formatters";
import { Pagination } from "../../../../../partials/controls";
import { useDepositUIContext } from "../DepositUIContext";
import { NotFound } from "../../../../components/message/NotFound";

function DepositTable(props) {
  const { requestDeposit, results, meta, listLoading } = props;

  // Customers UI Context
  const depositUIContext = useDepositUIContext();
  const depositUIProps = useMemo(() => {
    return {
      ids: depositUIContext.ids,
      setIds: depositUIContext.setIds,
      queryParams: depositUIContext.queryParams,
      setQueryParams: depositUIContext.setQueryParams
    };
  }, [depositUIContext]);

  // Redux state
  useEffect(() => {
    requestDeposit(depositUIProps.queryParams.pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDeposit, depositUIProps.queryParams.pageNumber]);


  // Table columns
  const columns = [
    {
      text: 'STATUS',
      align: 'center',
      headerAlign: 'center',
      dataField: "status",
      sort: false,
      formatter: columnFormatters.StatusColumnFormatter
    },
    {
      text: 'DATA',
      align: 'center',
      headerAlign: 'center',
      dataField: "due_date",
      sort: false,
      formatter: columnFormatters.DateColumnFormatter
    },
    {
      text: 'VALOR',
      align: 'center',
      headerAlign: 'center',
      dataField: "value",
      sort: false,
      formatter: columnFormatters.ValueColumnFormatter
    },
    {
      text: 'CÓDIGO',
      align: 'center',
      headerAlign: 'center',
      dataField: "writable_line",
      sort: false,
      formatter: columnFormatters.CodeColumnFormatter
    },
    {
      text: 'AÇÕES',
      align: 'center',
      headerAlign: 'center',
      dataField: "id",
      sort: false,
      formatter: columnFormatters.ButtonColumnFormatter
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: meta.pagination.total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: meta.pagination.per_page,
    paginationSize: meta.pagination.total_pages,
    page: depositUIProps.queryParams.pageNumber
  };
  return (
    (() => {
      if (results.length > 0) {
        return (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    remote
                    keyField="id"
                    data={results === null ? [] : results}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    onTableChange={getHandlerTableChange(
                      depositUIProps.setQueryParams
                    )}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={results} />
                    <NoRecordsFoundMessage entities={results} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )
      } else {
        return <NotFound message="Nenhum dado encontrado no momento." />;
      }
    })()
  );
}

export default connect(
  ({ deposit }) => ({
    results: deposit.results,
    meta: deposit.meta,
    listLoading: deposit.listLoading
  }),
  deposit.actions)(DepositTable);
