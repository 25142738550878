import React from 'react';
import { CardMessage } from '../../components/message/CardMessage';
import { toAbsoluteUrl } from '../../../helpers';
import SVG from "react-inlinesvg";
import { appStoreURI, playStoreURI } from '../../utils/storeURI.utils';
import { useLang } from '../../../i18n/Metronici18n';

export const SecurityTransactionRegister = (props) => {
    const locale = useLang();

    return (
        <div className="row mt-20 justify-content-center">
            <CardMessage>
                <p className="text-center">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Register/Register-Security-Required.svg")} />
                </p>
                <h5>
                    <span class="text-dark-65">
                        &nbsp;&nbsp;Você ainda não possui uma senha de segurança.
                    </span>
                </h5>
                <div className="text-center pl-2 pt-10">
                    <p>
                        Ela é necessária para aprovar qualquer tipo de transação.
                    </p>
                    <p>
                        Baixe o app agora, e cadastre sua senha.
                    </p>

                    <div className="row justify-content-center mt-10">
                        {playStoreURI() !== '' && (
                            <div className="col-md-5">
                                <a
                                    rel="noopener noreferrer"
                                    href={playStoreURI()}
                                    target="_blank"
                                >
                                    <img
                                        alt="android"
                                        src={
                                            locale === 'pt'
                                                ? '/media/logos/bt_android_pt.svg'
                                                : '/media/logos/bt_android_en.svg'
                                        }
                                        width="150px"
                                    ></img>
                                </a>
                            </div>
                        )}
                        {appStoreURI() !== '' && (
                            <div className="col-md-5">
                                <a
                                    rel="noopener noreferrer"
                                    href={appStoreURI()}
                                    target="_blank"
                                >
                                    <img
                                        alt="ios"
                                        src={
                                            locale === 'pt'
                                                ? '/media/logos/bt_ios_pt.svg'
                                                : '/media/logos/bt_ios_en.svg'
                                        }
                                        width="150px"
                                    ></img>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </CardMessage>
        </div>
    )
}