import React from 'react';
import { useSelector } from 'react-redux';

import QuickShortcuts from '../quick-shortcuts-batch/QuickShortcuts';

export function SubasideShort() {
  const { financial } = useSelector(({ auth }) => ({
    financial: auth.financial,
  }));

  return (
    <div className="col-md-3 col-xs-12 col-lg-3">
      {/* <Balance /> */}
      {(() => {
        if (financial.status === 'approved') {
          return <QuickShortcuts />;
        }
      })()}
    </div>
  );
}
