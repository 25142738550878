import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { useIntl } from 'react-intl';

export default function ErrorFields(props) {
  const { error_fields, onHide } = props;
  const intl = useIntl();

  return (
    <Modal
      show={error_fields !== '' ? true : false}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <p className="text-align-center">
            <FormattedMessage id="MODAL.ERROR.TITLE" />
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-10">
          <div className="col-md-12">
            {(() => {
              if (!_.isEmpty(error_fields)) {
                return error_fields.map((v, i) => {
                  let message = '';

                  const id = _.get(v, 'idTranslate');
                  if (_.isEmpty(id)) message = _.get(v, 'message');
                  else message = intl.formatMessage({ id });

                  return (
                    <p className="text-danger" key={i}>
                      {message}
                    </p>
                  );
                });
              }
            })()}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-default btn-elevate"
          >
            Fechar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
