import React, { useState, useEffect } from 'react';
import { Card, CardBody } from '../../../../../partials/controls';

import { moneyMask } from '../../../../utils/currency.utils';
import { cpfMask, cnpjMask } from '../../../../utils/mask_cpf_cnpj.utils';
import { dateFormatted } from '../../../../utils/date.utils';
import SVG from 'react-inlinesvg';
import { boletoBarcodeSvg } from 'boleto-barcode-svg';

const DepositBatchList = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    const teste = JSON.parse(localStorage.getItem('batch'));
    if (teste) {
      setData(teste);
      setTitle(localStorage.getItem('batchtitle'));

      setTimeout(() => {
        localStorage.removeItem('batch');
        localStorage.removeItem('batchtitle');
      }, [5000]);
    } else {
      window.location.href = '/conta/depositolote';
    }
  }, []);

  return (
    <div>
      {data &&
        data.map(lote => {
          return (
            <div key={lote.deposit.id} className="row">
              {/** begin::Vencimento */}
              <Card className="col-xs-12 col-lg-4 col-md-4 mr-2">
                <CardBody>
                  <h3>{moneyMask(lote.value)}</h3>
                  <p className="text-muted mt-1">
                    Data do vencimento:{' '}
                    <span>{dateFormatted(lote.due_date)}</span>
                  </p>

                  <h5>Multa: {moneyMask(lote.fine_value)}</h5>
                  {lote.fine_start_date !== null && (
                    <p className="text-muted mt-1">
                      Início da multa:{' '}
                      <span>{dateFormatted(lote.fine_start_date)}</span>
                    </p>
                  )}

                  <h5>Mora: {moneyMask(lote.mora_value)}</h5>
                  {lote.mora_start_date !== null && (
                    <p className="text-muted mt-1">
                      Início da mora:{' '}
                      <span>{dateFormatted(lote.mora_start_date)}</span>
                    </p>
                  )}
                </CardBody>
              </Card>
              {/** end::Vencimento */}

              {/** begin::Codigo_de_barras */}
              <Card className="col-xs-12 col-lg-7 col-md-7 ml-2">
                <CardBody>
                  <div className="col-md-12">
                    <h5>Lote: {title}</h5>
                    Pagador: <br />
                    {lote.payer.name} - {lote.payer.email} <br />
                    Documento: <br />
                    {lote.payer.document.length === 11
                      ? cpfMask(lote.payer.document)
                      : cnpjMask(lote.payer.document)}
                  </div>
                  <div className="col-md-12 text-center justify-content-center">
                    <p className="text-muted mt-1">{lote.deposit.bar_code}</p>
                    {(() => {
                      if (lote.deposit.writable_line) {
                        return (
                          <SVG
                            src={boletoBarcodeSvg(lote.deposit.writable_line)}
                          ></SVG>
                        );
                      }
                    })()}
                  </div>
                  <div className="col-md-12 text-center pt-5 text-center justify-content-center">
                    <a
                      href={lote.deposit.payment_link}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="btn btn-dark"
                    >
                      Boleto PDF
                    </a>
                  </div>
                </CardBody>
              </Card>
              {/** end::Codigo_de_barras */}
            </div>
          );
        })}
    </div>
  );
};

export default DepositBatchList;
// export default connect(
//   ({ deposit: { billDeposit } }) => ({ billDeposit }),
//   deposit.actions
// )(DepositBatchList);
