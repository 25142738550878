import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux';
import {
	Card
} from "../../../../partials/controls";
import { CardMessage } from '../../../components/message/CardMessage';
import { AutomaticDebit } from './AutomaticDebit';
import * as deposit from "../../MyAccount/_redux/deposit/depositRedux";
import SecurityTransaction from '../../SecurityTransaction';
import { useHtmlClassService } from '../../../_core/MetronicLayout';

function AutomaticDebitPage(props) {
	const { requestDebits, debits, authorize, operation } = props;

	useEffect(() => {
		requestDebits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestDebits]);

	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
			return {
					colors: uiService.getColors()
			};
	}, [uiService]);

	return (
		<div className="col-md-8 column">
			<CardMessage
				message={<p className="text-muted">Débitos pendentes</p>}
			>
				<h3>Escolha qual débito deseja pagar</h3>
			</CardMessage>
			<Card className="align-items-center">
				{(() => {
					if(operation.length !== 0) {
							return <SecurityTransaction 
													layoutProps={layoutProps} 
													operation={operation} 
											/>
					}

					if(debits.results) {
						return debits.results.map((v, i) => {
							return (
								v.status !== 'unauthorized' &&
								<AutomaticDebit key={i} debits={v} authorize={authorize} />
							)
						})
					}

					return (
						<p className="text-center text-muted">
							Não existem débitos programados
						</p>
					)
				})()}
			</Card>
		</div>
	)
}

export default connect(
	({ deposit }) => ({ 
		debits: deposit.automaticDebits,
		operation: deposit.operation
	}),
	deposit.actions
)(AutomaticDebitPage);
