import { pixSlice, callTypes } from './actions';
import PixServices from '../../../../../services/PixServices';
import { HTTPCodes } from '../../../../../services/enums/HTTPCodes';

const { actions } = pixSlice;

export const pixFetchKey = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return PixServices.getPixKeyData(params)
    .then(res => {
      if (res.data.message) {
        dispatch(actions.fetchError([{ message: res.data.message }]));
      } else {
        dispatch(actions.fetchKeyData(res.data));
      }
    })
    .catch(err => {
      let message_error = '';

      switch (err.response.status) {
        case HTTPCodes.NOT_FOUND:
          message_error = `Serviço indisponível`;
          dispatch(actions.fetchError([{ message: message_error }]));
          break;

        case HTTPCodes.UNKNOWN_SERVER_ERROR:
          message_error = `[${err.response.status}] Serviço indisponível`;
          dispatch(actions.fetchError([{ message: message_error }]));
          break;

        case HTTPCodes.FORBIDDEN:
          const {
            data: { message },
          } = err.response;
          message_error = `[${err.response.status}] ${message}`;
          dispatch(actions.fetchError([{ message: message_error }]));

          break;

        default:
          const {
            data: { field_errors },
          } = err.response;
          dispatch(actions.fetchError(Object.values(field_errors)[0]));
          break;
      }
    });
};

export const makePixTransfer = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return PixServices.makePixTransfer(params)
    .then(res => {
      const { operation } = res.data;
      dispatch(actions.fetchTransfer(operation));
    })
    .catch(err => {
      let message_error = '';

      switch (err.response.status) {
        case HTTPCodes.UNKNOWN_SERVER_ERROR:
          message_error = `[${err.response.status}] Serviço indisponível`;
          dispatch(actions.fetchError([{ message: message_error }]));
          break;

        case HTTPCodes.FORBIDDEN:
          const {
            data: { message },
          } = err.response;
          message_error = `[${err.response.status}] ${message}`;
          dispatch(actions.fetchError([{ message: message_error }]));

          break;

        default:
          const {
            data: { field_errors },
          } = err.response;
          dispatch(actions.fetchError(Object.values(field_errors)[0]));
          break;
      }
    });
};

export const fetchError = params => dispatch => {
  dispatch(actions.fetchError(params));
};

export const resetOperation = operation => dispatch => {
  dispatch(actions.fetchTransfer(operation));
};
