export const initialState = {
  send: false,
  register: {},
  documents: {},
  loadType: '',
  error_fields: '',
  file_selfie: '',
  file_document_selfie: '',
  file_document_front: '',
  file_document_back: '',
  file_proof_of_residence: '',
  file_company_document: '',
  file_company_social_contract: '',
};

export const types = {
  UserPersonalRegister: '[Register] User Personal',
  UserPersonalRegisterLoaded: '[Loaded] User Personal',
  RegisterDocuments: '[Register] Send Documents',
  UpdateDocuments: '[Update] Send Documents',
  LoadDocuments: '[Loaded] Load Documents',
  LoadSend: '[Loaded] Load Send',
  LoadSuccessSend: '[Loaded] Load Success Send',
  ErrorsLoaded: '[Errors] Error Loaded',
  LoadType: '[Loaded] Load Type',

  SelfieFile: '[Loaded] Selfie File',
  DocumentSelfieFile: '[Loaded] Document Selfie File',
  DocumentFrontFile: '[Loaded] Document Front File',
  DocumentBackFile: '[Loaded] Document Back File',
  ProofOfResidenceFile: '[Loaded] Proof of Residence File',
  CompanyDocumentFile: '[Loaded] Company Document File',
  CompanySocialContract: '[Loaded] Company Social Contract',
  ClearDocuments: '[Clear] Documents',
};

export const actions = {
  register: register => ({
    type: types.UserPersonalRegister,
    payload: { register },
  }),
  load: register => ({
    type: types.UserPersonalRegisterLoaded,
    payload: { register },
  }),
  loadType: register => ({
    type: types.LoadType,
    payload: { register },
  }),
  loadSend: register => ({
    type: types.LoadSend,
    payload: { register },
  }),
  loadSuccessSend: register => ({
    type: types.LoadSuccessSend,
    payload: { register },
  }),
  registerDocuments: register => ({
    type: types.RegisterDocuments,
    payload: { register },
  }),
  updateDocuments: register => ({
    type: types.UpdateDocuments,
    payload: { register },
  }),
  loadDocuments: register => ({
    type: types.LoadDocuments,
    payload: { register },
  }),
  loadSelfie: register => ({
    type: types.SelfieFile,
    payload: { register },
  }),
  loadDocumentSelfie: register => ({
    type: types.DocumentSelfieFile,
    payload: { register },
  }),
  loadDocumentFront: register => ({
    type: types.DocumentFrontFile,
    payload: { register },
  }),
  loadDocumentBack: register => ({
    type: types.DocumentBackFile,
    payload: { register },
  }),
  loadProofFile: register => ({
    type: types.ProofOfResidenceFile,
    payload: { register },
  }),
  loadCompanyFile: register => ({
    type: types.CompanyDocumentFile,
    payload: { register },
  }),
  loadCompanyContract: register => ({
    type: types.CompanySocialContract,
    payload: { register },
  }),
  fetchErrors: error => ({
    type: types.ErrorsLoaded,
    payload: { error },
  }),
  clearDocuments: register => ({
    type: types.ClearDocuments,
    payload: { register },
  }),
};
