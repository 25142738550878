import React from 'react';

export function PasswordInput(props) {

return (
        <input
            type={props.type}
            name={props.name}
            className={props.classes}
            {...props.formik}
        />
    )
}