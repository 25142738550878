
export const initialState = {
    auth_transaction: {},
    authorized: false
}

export const types = {
    AuthTransactionRequested: "[Auth Transaction] Action",
    AuthTransactionLoaded: "[Auth Transaction] Auth Transaction Load",
    AuthorizedLoaded: "[Authorized Load] Authorize Load",
    AuthorizeLoaded: "[Authorize Load] Auth Load",
    AuthorizedRequest: "[Authorized Request] Auth Request"
};

export const actions = {
    verifyTransaction: operation => ({ type: types.AuthTransactionRequested, payload: { operation } }),
    fetchAuthTransaction: operation => ({ type: types.AuthTransactionLoaded, payload: { operation } }),
    fetchAuth: operation => ({ type: types.AuthorizedRequest, payload: { operation } }),
    fetchAuthLoad: operation => ({ type: types.AuthorizedLoaded, payload: { operation } }),
    fetchAuthorize: operation => ({ type: types.AuthorizeLoaded, payload: { operation } })
};