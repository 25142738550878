import React, { useMemo } from 'react';
import { Card, CardBody } from '../../../../partials/controls';
import { Subaside } from '../../../components/subaside/Subaside';
import { ButtonCustom } from '../../../components/button/ButtonCustom';
import DepositTable from './table/DepositTable';
import { useHtmlClassService } from '../../../_core/MetronicLayout';

const DepositPage = props => {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <div className="row">
      <Subaside />

      <Card className="col-xs-8 col-xl-8 col-md-8">
        <CardBody>
          <div className="row">
            <div className="col-md-10">
              <h4>Lista de Boletos</h4>
              <p className="text-muted mt-1">Acompanhe seus boletos em lote</p>
            </div>

            <div className="col-md-2 col-md-offset-2">
              <ButtonCustom
                onClick={e => {
                  window.location.href = '/conta/depositolotenew';
                }}
                layoutProps={layoutProps}
              >
                Novo Lote
              </ButtonCustom>
            </div>
          </div>
          <DepositTable />
        </CardBody>
      </Card>
    </div>
  );
};

export default DepositPage;
