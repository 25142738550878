import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./ExternalUIHelpers";

const ExternalUIContext = createContext();

export function useExternalUIContext() {
  return useContext(ExternalUIContext);
}

export const ExternalUIConsumer = ExternalUIContext.Consumer;

export function ExternalUIProvider({ externalUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initExternalTransfer = {
    id: undefined,
    favored: "",
    document: "",
    value: 0,
    status: "",
    account_number: "",
    account_digit: "",
    account_type: "",
    bank_branch: "",
    bank_branch_digit: "",
    created_at: "",
    updated_at: "",
    bank: []
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initExternalTransfer,
    openFetchExternalDialog: externalUIEvents.openFetchExternalDialog
  };

  return <ExternalUIContext.Provider value={value}>{children}</ExternalUIContext.Provider>;
}