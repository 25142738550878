import { internalSlice, callTypes } from "./internalSlice";
import PaymentServices from '../../../../../services/PaymentServices';
import { HTTPCodes } from '../../../../../services/enums/HTTPCodes';

const { actions } = internalSlice;

export const fetchInternalTransfer = page => dispatch => {
    return PaymentServices
        .getAllInternalTransfers(page)
        .then(res => {
            const { meta: { pagination }, results } = res.data;

            dispatch(actions.internalTransferFetched({ pagination, entities: results }));
        })
        .catch(err => {
            err.clientMessage = err.message;
            dispatch(actions.catchError({ err, callType: callTypes.list }));
        });
};

export const createInternalTransfer = params => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return PaymentServices
        .generateInternalTransfer(params)
        .then(res => {
            const { operation } = res.data;
            dispatch(actions.fetchTransfer(operation));
        })
        .catch((err) => {
            let message_error = '';

            switch (err.response.status) {
                case HTTPCodes.UNKNOWN_SERVER_ERROR:
                    message_error = `[${err.response.status}] Serviço indisponível`;
                    dispatch(actions.fetchError([{ message: message_error }]));
                break;

                case HTTPCodes.FORBIDDEN:
                    const { data: { message } } = err.response;
                    message_error = `[${err.response.status}] ${message}`;
                    dispatch(actions.fetchError([{ message: message_error }]));

                break;

                default:
                    const { data: { field_errors } } = err.response;
                    dispatch(actions.fetchError(Object.values(field_errors)[0]));
                break;
            }
        });
}

export const fetchError = params => dispatch => {
    dispatch(actions.fetchError(params));
}

export const resetOperation = operation => dispatch => {
    dispatch(actions.fetchTransfer(operation));
}

