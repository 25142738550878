import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../helpers';

export function ButtonColumnFormatter(cellContent, row) {
  const loadBatch = async row => {
    localStorage.setItem('batch', JSON.stringify(row.data));
    localStorage.setItem('batchtitle', row.title);
    if (row.data) {
      window.location.href = '/conta/depositolote/ver';
    }
  };

  return (
    // <Link to={`boleto/${row.id}`}>
    <button onClick={() => loadBatch(row)} className="btn btn-default">
      <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')}></SVG>
    </button>
    // </Link>
  );
}
