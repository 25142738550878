// Libraries
import create from 'zustand';
import produce from 'immer';

export const billetBatchStore = create(set => {
  const setState = fn => set(produce(fn));
  const initialState = {
    title: '',
    billets: [],
    currentBillet: null,
  };

  return {
    state: {
      ...initialState,
    },
    actions: {
      updateTitle: param =>
        setState(({ state }) => {
          state.title = param;
        }),
      updateBillets: param =>
        setState(({ state }) => {
          state.billets = param;
        }),
      updateCurrentBillet: param =>
        setState(({ state }) => {
          state.currentBillet = param;
        }),
    },
  };
});
