export const phoneFormatted = value => {
  return value.replace(/(\d{5})(\d{4})/, '$1-$2');
};

export const phoneNumberFormatted = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const removeFormatter = value => {
  let number = value.replace('-', '');
  number = number.replace('(', '');
  number = number.replace(')', '');
  number = number.split(' ');

  return {
    country_code: 55,
    region_code: number[0],
    number: number[1],
  };
};
