import React from "react";
import { FormattedMessage } from "react-intl";
import { dateFormatted } from "../../../../../utils/date.utils";

export function DateColumnFormatter(cellContent, row) {
    const lineBreak = (date) => {
        return (
            <div className="text-muted text-center d-flex flex-column">
                <span>
                    <FormattedMessage 
                        id="MY_ACCOUNT.TITLE.DUE_DATE"
                    />
                </span>
                <span>
                    {dateFormatted(date)}
                </span>
            </div>
        );
    }  
    return (
        lineBreak(row.due_date)
    );
}
