import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from '../../../../../helpers';
import * as uiHelpers from '../DepositUIHelpers';
import * as columnFormatters from './formatters';
import { Pagination } from '../../../../../partials/controls';
import { NotFound } from '../../../../components/message/NotFound';
import BatchServices from '../../../../../services/BatchServices';

function DepositTable(props) {
  const [meta, setMeta] = useState([]);
  const [results, setResults] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const loadBatch = async () => {
    setListLoading(true);

    let info = await BatchServices.getAllBoletos();
    let fav = [];

    setListLoading(false);

    if (info.data.results.length > 0) {
      info.data.results.forEach((data, index) => {
        fav.push({
          title: data.title,
          size: data.items.results.length,
          data: data.items.results,
        });
      });

      setResults(fav);
      setMeta(info.data.meta);
    }
  };

  useEffect(() => {
    loadBatch();
  }, []);

  const columns = [
    {
      text: 'Título',
      align: 'center',
      headerAlign: 'center',
      dataField: 'title',
      sort: true,
    },
    {
      text: 'Número de Lotes',
      align: 'center',
      headerAlign: 'center',
      dataField: 'size',
      sort: false,
    },
    {
      text: 'AÇÕES',
      align: 'center',
      headerAlign: 'center',
      dataField: 'id',
      sort: false,
      formatter: columnFormatters.ButtonColumnFormatter,
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: meta.pagination?.total_count || 1,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: meta.pagination?.per_page || 1,
    paginationSize: meta.pagination?.total_pages || 1,
    page: meta.pagination?.current_page || 1,
  };
  return (() => {
    if (results.length > 0) {
      return (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  keyField="id"
                  data={results === null ? [] : results}
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={() => {}}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={results} />
                  <NoRecordsFoundMessage entities={results} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      );
    } else {
      return <NotFound message="Nenhum dado encontrado no momento." />;
    }
  })();
}

export default DepositTable;
