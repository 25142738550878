import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import * as actions from '../../../redux/authorize_transaction/actions';

import { Card, CardBody } from '../../../partials/controls';
import { Button } from 'react-bootstrap';
import { CardMessage } from '../../components/message/CardMessage';
import ModalNotice from '../../components/alert/ModalNotice';
import { useHtmlClassService } from '../../_core/MetronicLayout';

function SecurityTransaction(props) {
  const {
    intl,
    operation,
    verifyTransaction,
    loadingAuthorize,
    fetchAuth,
  } = props;
  const [loading, setLoading] = useState(false);
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  // Schema de validação dos campos
  const ExternaTransferSchema = Yup.object().shape({
    transaction_password: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });
  // - - -

  const enableLoading = () => {
    setLoading(true);
  };

  const initialValues = {
    transaction_password: '',
    verification_code: '',
  };
  // Inicializando e validando formulário
  const formik = useFormik({
    initialValues,
    validationSchema: ExternaTransferSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        try {
          verifyTransaction({
            latitude: '',
            longitude: '',
            transaction_password: values.transaction_password,
            verification_code: values.verification_code,
            id: operation.id,
          });
        } catch (error) {
          console.log(error);
          setSubmitting(false);
          enableLoading();
        }
      }, 1000);
    },
  });
  // - - -

  return (
    <>
      {/** begin::Modal */}
      <ModalNotice
        show={loadingAuthorize}
        onHide={() => {
          fetchAuth(false);
          setLoading(false);
        }}
        title="AUTH.TRANSACTION.SUCCESS"
        message="AUTH.TRANSACTION.SUCCESS"
      />
      {/** end::Modal */}

      <CardMessage message="Digite abaixo sua senha de autorização e, se pedido, o código de autorização enviado." />

      <Card className="col-xs-12 col-xl-12 col-md-12">
        <CardBody>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              {/** begin::Valor*/}
              <div className="col-md-12 col-lg-12 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="transaction_password">
                    Senha de autorização:
                  </label>
                  <input
                    type="password"
                    className="form-control h-auto py-3 px-6"
                    name="transaction_password"
                    {...formik.getFieldProps('transaction_password')}
                  />
                  {formik.touched.transaction_password &&
                  formik.errors.transaction_password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.transaction_password}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Valor*/}
            </div>

            {/** begin::Favorecido*/}
            {(() => {
              if (
                operation.needs_verification_code !== 0 &&
                operation.needs_verification_code
              ) {
                return (
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="verification_code">
                          Código de autorização enviado:
                        </label>
                        <input
                          type="text"
                          placeholder="* código enviado via e-mail/sms"
                          className="form-control h-auto py-3 px-6"
                          name="verification_code"
                          {...formik.getFieldProps('verification_code')}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
              return;
            })()}
            {/** end::Favorecido*/}

            <div className="row">
              <div className="col-md-12 col-md-offset-2">
                <Button
                  style={{
                    backgroundColor: `${layoutProps.colors.primary}`,
                  }}
                  type="submit"
                  size="lg"
                  block
                  disabled={loading}
                >
                  Autorizar transação
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </Button>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
}
export default injectIntl(
  connect(
    ({ auth_transaction }) => ({
      loadingAuthorize: auth_transaction.loadingAuthorize,
    }),
    actions.actions
  )(SecurityTransaction)
);
