import React, { useState } from 'react';

import {
    Card,
    CardHeader,
    CardBody
} from '../../../../../partials/controls';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { injectIntl } from "react-intl";
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as auth from "../../../../pages/User/Auth/_redux/authRedux";
import ErrorFields from '../../../../components/alert/ErrorFields';
import ModalNotice from '../../../../components/alert/ModalNotice';

function AddressForm(props) {
    const { intl, address, updateAddress, error_fields, fetchError } = props;
    const [loading, setLoading] = useState(false);

    // Schema de validação dos campos
    const Schema = Yup.object().shape({
        zip_code: Yup.number()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
    });
    // - - -

    const enableLoading = () => {
        setLoading(true);
    };

    const initialValues = {
        country: address.country,
        state: address.state,
        city: address.city,
        zip_code: address.zip_code,
        district: address.district,
        street_name: address.street_name,
        number: address.number,
        complement: address.complement
    }
    // Inicializando e validando formulário
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading()
            setTimeout(() => {
                try {
                    updateAddress(values)
                } catch (error) {
                    setSubmitting(false);
                }
            }, 1000)
        },
    });
    // - - - 

    return (<>
        {/** begin::ModalError */}
        <ErrorFields
            error_fields={error_fields}
            show={(error_fields !== '') ? (true) : (false)}
            onHide={() => {
                fetchError('')
                setLoading(false)
            }}
        />
        {/** end::ModalError */}
        
        {/** begin::Modal Open Account */}
        <ModalNotice
            show={(error_fields === '' && loading) ? (true) : (false)}
            onHide={() => {
                setLoading(false)
            }}
            title="USER.ADDRESS.TITLE.SUCCESS"
            message="USER.ADDRESS.MESSAGE.SUCCESS" 
        />
        {/** end::Modal Open Account */}

        <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardHeader
                title="Alterar meu endereço"
            />
            <CardBody>
                <form
                    onSubmit={formik.handleSubmit}>

                    <div className="row">
                        {/** begin::CEP*/}
                        <div className="col-md-3 col-lg-3 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="zip_code">CEP:</label>
                                <input
                                    type="number"
                                    className="form-control h-auto py-4 px-6"
                                    name="zip_code"
                                    {...formik.getFieldProps("zip_code")}
                                />
                                {formik.touched.zip_code && formik.errors.zip_code ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.zip_code}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::CEP*/}

                        {/** begin::Endereço*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="street_name">Endereço:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="street_name"
                                    {...formik.getFieldProps("street_name")}
                                />
                            </div>
                        </div>
                        {/** end::Endereço*/}

                        {/** begin::Numero*/}
                        <div className="col-md-3 col-lg-3 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="number">Número:</label>
                                <input
                                    type="number"
                                    className="form-control h-auto py-4 px-6"
                                    name="number"
                                    {...formik.getFieldProps("number")}
                                />
                            </div>
                        </div>
                        {/** end::Numero*/}
                    </div>

                    <div className="row">
                        {/** begin::Bairro*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="district">Bairro:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="district"
                                    {...formik.getFieldProps("district")}
                                />
                            </div>
                        </div>
                        {/** end::Bairro*/}

                        {/** begin::Complemento*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="complement">Complemento:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="complement"
                                    {...formik.getFieldProps("complement")}
                                />
                            </div>
                        </div>
                        {/** end::Complemento*/}
                    </div>

                    <div className="row">
                        {/** begin::Cidade*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="city">Cidade:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="city"
                                    {...formik.getFieldProps("city")}
                                />
                            </div>
                        </div>
                        {/** end::Cidade*/}

                        {/** begin::Estado*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="state">Estado:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="state"
                                    {...formik.getFieldProps("state")}
                                />
                            </div>
                        </div>
                        {/** end::Estado*/}
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-md-offset-2">
                            <Button 
                                style={{
                                    backgroundColor: `${props.layoutProps.colors.primary}`
                                }}
                                type="submit"
                                size="lg"
                                block
                            >
                                Alterar meu endereço
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </Button>
                        </div>
                    </div>

                </form>
            </CardBody>
        </Card>
    </>);
}

export default injectIntl(connect(
    ({ auth }) => ({ address: auth.address, error_fields: auth.error_fields }),
    auth.actions)(AddressForm));
