import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as actions from "../../_redux/internal/internalActions";

import {
    Card,
    CardBody
} from '../../../../../partials/controls';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { injectIntl } from "react-intl";
import { CardMessage } from '../../../../components/message/CardMessage';
import ErrorFields from '../../../../components/alert/ErrorFields';
import { moneyMask, onlyNumbers, removeCurrencyMask, formatPrice } from '../../../../utils/currency.utils';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import DatePicker from 'react-datepicker';

import UserServices from '../../../../../services/UserServices';

function InternalForm(props) {
    const { intl, createInternalTransfer, error_fields, fetchError } = props;
    const [loading, setLoading] = useState(false);
    const [accountInfo, setAccountInfo] = useState([]);

    // Schema de validação dos campos
    const Schema = Yup.object().shape({
        account_number: Yup.number()
        .required(
            intl.formatMessage({
                id: "BILL.REQUIRED_FIELD",
            })
        ),
        value: Yup.string()
        .required(
            intl.formatMessage({
                id: "BILL.REQUIRED_FIELD",
            })
        )
    });
    // - - -

    const enableLoading = () => {
        setLoading(true);
    };

    // Inicializando e validando formulário
    const formik = useFormik({
        initialValues: {
            account_number: '',
            value: 'R$ 0,00',
            scheduled_date: new Date(),
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            const data = {
                ...values,
                value: removeCurrencyMask(values.value)
            };

            delete data.scheduled_date;

            const now = (new Date()).toISOString().substr(0, 10);
            const scheduledDate = values.scheduled_date.toISOString().substr(0, 10);
            if (now !== scheduledDate) data.scheduled_date = scheduledDate;
            
            setTimeout(() => {
                try {
                    enableLoading(); 
                    createInternalTransfer(data)
                } catch (error) {
                    setSubmitting(false);
                    enableLoading(); 
                }
            }, 1000)
        }
    });
    // - - - 

    const loadInfo = async () => {
        let info = await UserServices.getAccount();
        setAccountInfo(info.data);
    };
    
    useEffect(() => {
        loadInfo();
    },[]);

    const updateTax = () => {
        if (accountInfo.length === 0) return;
        
        let tax = 0;

        if (accountInfo.limits.internal_transfer.fee_amount > 0) {
            tax = accountInfo.limits.internal_transfer.fee_amount;
        }

        return `Taxa da Transferência: ${formatPrice(tax)}`;
    };

    const updateTaxPercent = () => {
        if (accountInfo.length === 0) return;
        let tax = 0;

        if (accountInfo.limits.internal_transfer.fee_percent > 0) {
            tax = accountInfo.limits.internal_transfer.fee_percent;

            tax = (tax / 100) * removeCurrencyMask(formik.values.value);
        }

        return `Taxa da Operação: ${formatPrice(tax)}`;
    };

    return (<>

        {/** begin::ModalError */}
        <ErrorFields
            error_fields={error_fields}
            show={(error_fields !== '') ? (true) : (false)}
            onHide={() => {
                fetchError('')
                setLoading(false)
            }}
        />
        {/** end::ModalError */}

        <CardMessage 
            message="Preencha corretamente o número da conta de destino para realizar uma transferência entre contas internamente." 
        >
            <div>
                <br />
                {updateTax()}
                <br />  
                {updateTaxPercent()}
            </div>
        </CardMessage>
        <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardBody>
                <form 
                    onSubmit={formik.handleSubmit}>

                    <div className="row"> 
                        
                        {/** begin::Conta*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="account_number">Número da Conta:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="account_number"
                                    {...formik.getFieldProps("account_number")}
                                    onChange={(e) => {
                                        formik.setFieldValue("account_number", onlyNumbers(e.target.value, 15));
                                    }}
                                />                 
                                {formik.touched.account_number && formik.errors.account_number ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.account_number}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Conta*/}

                        {/** begin::Valor*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="value">Valor:</label>
                                <input
                                    placeholder="R$ 0,00"
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="value"
                                    {...formik.getFieldProps("value")}
                                    onChange={(e) => {
                                        formik.setFieldValue("value", moneyMask(e.target.value));
                                    }}
                                />            
                                {formik.touched.value && formik.errors.value ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.value}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Valor*/}

                        {/** begin::ScheduledDate*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="scheduled_date">Data:</label>
                                <DatePicker
                                    minDate={new Date()}
                                    className="form-control h-auto py-4 px-6"
                                    style={{ width: '100%' }}
                                    placeholderText="dd/mm/yyyy"
                                    selected={formik.values.scheduled_date}
                                    onChange={val => {
                                        formik.setFieldValue('scheduled_date', val);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        {/** end::ScheduledDate*/}
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-md-offset-2">
                            <ButtonCustom
                                layoutProps={props.layoutProps}
                                disabled={loading}
                            >
                                Realizar transferência
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </ButtonCustom>
                        </div>
                    </div>
                   
                </form>
            </CardBody>
        </Card>
    </>);
}

export default injectIntl(connect(
    ({ internal }) => ({ 
        message: internal.error,
        error_fields: internal.error_fields
    }), actions)(InternalForm));
