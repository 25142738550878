import { ENDPOINT } from "./enums/EndPoint";
import ApiServices from "./ApiServices";

/**
 * Gera um pagamento via TED
 */
export function generateExternalTransfer(params) {
    return ApiServices.post(ENDPOINT.EXTERNAL_TRANSFER, params.operation);
}

/**
 * Busca os últimos pagamentos via TED,
 * por página.
 * @param {number} page
 */
export function getAllExternalTransfers(page) {
    return ApiServices.get(ENDPOINT.EXTERNAL_TRANSFER, {
        params: {
          page: page
        }
    });
}

/**
 * Gera um pagamento via transferência interna.
 */
export const generateInternalTransfer = (params) => {
    return ApiServices.post(ENDPOINT.INTERNAL_TRANSFER, 
        params
    );
}

/**
 * Busca os últimos pagamentos via 
 * transferência interna por página.
 * @param {number} page
 */
export function getAllInternalTransfers(page) {
    return ApiServices.get(ENDPOINT.INTERNAL_TRANSFER, { 
        params: {
            page: page
        }
    });
}

/**
 * Busca os últimos pagamentos via boleto
 * @param {number} page
 */
const getAllBills = (page) => {
    return ApiServices.get(`${ENDPOINT.BILL_PAYMENTS}`, {
        params: {
            page: page
        }
    });
}

/**
 * Realiza o pagamento de um boleto
 */
const payBill = (params) => {
    return ApiServices.post(`${ENDPOINT.BILL_PAYMENTS}`, params)
}

/**
 * Verifica as informações de um boleto,
 * pelo código de barras
 * @param {number} bar_code
 */
const infoBill = ({ bar_code }) => {
    return ApiServices.get(`${ENDPOINT.BILL_INFO}/${bar_code}`)
}

/**
 * Verifica as informações de um comprovante de pagamento,
 * pelo id do pagamento
 * @param {number} id
 */
const infoPaymentProof = ({ id }) => {
    return ApiServices.get(`${ENDPOINT.BILL_PAYMENTS}/${id}`)
}

/**
 * Buscar favoritos
 */
 const getFavoreds = (page = 1) => {
    return ApiServices.get(`${ENDPOINT.FAVOREDS}?page=${page}`)
}

/**
 * Remover favoritos
 */
 const removeFavoreds = (id) => {
    return ApiServices.remove(`${ENDPOINT.FAVOREDS}/${id}`)
}

/**
 * Verifica as informações de uma movimentação
 * @param {number} id
 */
const getSecondVoucher = (id, endpoint) => {
    return ApiServices.get(`${endpoint}/${id}`);
};

const PaymentServices = {
    payBill,
    infoBill,
    getAllBills,
    generateExternalTransfer,
    getAllExternalTransfers,
    generateInternalTransfer,
    getAllInternalTransfers,
    infoPaymentProof,
    getFavoreds,
    removeFavoreds,
    getSecondVoucher,
}
export default PaymentServices;