import React, { useMemo } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../helpers";
import { ButtonCustom } from "../../../../components/button/ButtonCustom";
import { useHtmlClassService } from "../../../../components";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function DocumentMain(props) {
	const uiService = useHtmlClassService();

	const layoutProps = useMemo(() => {
		return {
			colors: uiService.getColors()
		};
	}, [uiService]);

	return (
		<div className="card border-0 mt-5">
			<div className="card-header text-center border-0">
				<SVG
					title="Parabéns!"
					src={toAbsoluteUrl("/media/svg/icons/Register/Register-Success.svg")}
				>
				</SVG>
			</div>
			<div className="card-body flex-center text-center border-0 pt-5">
				<h4>Tudo pronto!</h4>

				<p className="text-muted">
					Sua conta foi criada e você já tem
					acesso a ela.
				</p>
				{/* begin: Message */}
				<Alert style={{
					color: '#F11F52',
					backgroundColor: '#f7adbd',
				}}>
					<p>
						<b>Fique atento: </b>
						<FormattedMessage id="AUTH.REGISTER.TRANSACTIONS" />
					</p>
				</Alert>
				{/* end: Message */}

				<ButtonCustom
					className="mt-25"
					layoutProps={layoutProps}
					inverse={true}
					size="sm"
					type="button"
					onClick={() => props.goToDocuments(true)}
				>
					Enviar documentos agora
                </ButtonCustom>
				<Link to="/dashboard">
					<ButtonCustom
						className="mt-3"
						layoutProps={layoutProps}
						size="sm"
						type="button"
					>
						Acessar minha conta
					</ButtonCustom>
				</Link>
			</div>
		</div>
	);
}