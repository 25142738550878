import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/pages/User/Auth/_redux/authRedux";
import * as resume from "../app/pages/MyAccount/_redux/resume/resumeRedux";
import * as extract from "../app/pages/MyAccount/_redux/extract/extractRedux";
import * as deposit from "../app/pages/MyAccount/_redux/deposit/depositRedux";
import * as settings from "../redux/config/settingsRedux";

import * as summary from "./balance/saga";
import * as authTransaction from "./authorize_transaction/saga";

import * as registerUser from "../app/pages/User/Register/_redux/saga";
import * as physicalUser from "../app/pages/User/Register/PhysicalPerson/_redux/saga";
import * as legalUser from "../app/pages/User/Register/LegalPerson/_redux/saga";
import * as phoneVerification from "../app/pages/User/Register/Verification/PhoneNumber/_redux/saga";
import * as zipCodeVerification from "../app/pages/User/Register/Verification/ZipCode/_redux/saga";
import * as passwordVerification from "../app/pages/User/Register/Verification/Password/_redux/saga";
import * as banksVerification from "../app/pages/User/Register/Verification/BankAccount/_redux/saga";

import * as external from "../app/pages/Transfer/_redux/external/saga";
import {internalSlice} from "../app/pages/Transfer/_redux/internal/internalSlice";

import {billSlice} from "../app/pages/Payment/_redux/bill/billSlice";

import {pixSlice} from "../app/pages/MyAccount/Pix/_redux/actions";

import {phoneRechargeSlice} from "../app/pages/OtherService/_redux/phone-recharge/PhoneRechargeSlice";


const appReducer = combineReducers({
  auth: auth.reducer,
  auth_transaction: authTransaction.reducer,
  physicalPerson: physicalUser.reducer,
  legalPerson: legalUser.reducer,
  phone: phoneVerification.reducer, 
  zipcode: zipCodeVerification.reducer,
  password: passwordVerification.reducer,
  allBanks: banksVerification.reducer,
  register: registerUser.reducer,
  settings: settings.reducer,
  deposit: deposit.reducer,
  resume: resume.reducer,
  summary: summary.reducer,
  extract: extract.reducer,
  external: external.reducer,
  internal: internalSlice.reducer,
  bill: billSlice.reducer,
  phone_recharge: phoneRechargeSlice.reducer,
  pix: pixSlice.reducer
});

export const rootReducer = (state, action) => {
  if(action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
}

export function* rootSaga() {
  yield all([
    settings.saga(),
    auth.saga(),
    physicalUser.saga(),
    phoneVerification.saga(),
    zipCodeVerification.saga(),
    passwordVerification.saga(),
    banksVerification.saga(),
    registerUser.saga(),
    legalUser.saga(),
    resume.saga(),
    external.saga(),
    deposit.saga(),
    extract.saga(),
    summary.saga(),
    authTransaction.saga()
  ]);
}
