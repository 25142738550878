const isRepeatingNumber = str => /^(\d)(\1){10}$/.test(str);

export const cpfValid = value => {
  const cpf = `${value}`.replace(/\D/g, '');

  if (
    cpf === '' ||
    cpf.length !== 11 ||
    !/^\d{11}$/.test(cpf) ||
    isRepeatingNumber(cpf)
  ) {
    return false;
  }

  const digits = cpf.split('').map(x => parseInt(x, 10));

  for (let j = 0; j < 2; j += 1) {
    let sum = 0;

    for (let i = 0; i < 9 + j; i += 1) {
      sum += digits[i] * (10 + j - i);
    }

    let checkDigit = 11 - (sum % 11);

    if (checkDigit === 10 || checkDigit === 11) {
      checkDigit = 0;
    }

    if (checkDigit !== digits[9 + j]) {
      return false;
    }
  }

  return true;
};

export const cnpjValid = value => {
  const cnpj = `${value}`.replace(/[^\d]+/g, '');

  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) return false;

  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;

  // Cáculo de validação
  const t = cnpj.length - 2;
  const d = cnpj.substring(t);
  const d1 = parseInt(d.charAt(0), 10);
  const d2 = parseInt(d.charAt(1), 10);
  const calc = x => {
    const n = cnpj.substring(0, x);
    let y = x - 7;
    let s = 0;
    let r = 0;

    // eslint-disable-next-line
    for (let i = x; i >= 1; i--) {
      // eslint-disable-next-line
      s += n.charAt(x - i) * y--;
      if (y < 2) y = 9;
    }

    r = 11 - (s % 11);
    return r > 9 ? 0 : r;
  };

  return calc(t) === d1 && calc(t + 1) === d2;
  // const reg = new RegExp(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/);
  // return reg.test(value);
};

export const phoneValid = value => {
  const qty = `${value}`.replace(/\D/g, '');
  return /\(\d{2}\)\s\d{4,5}-\d{4}/g.test(value) && qty.length <= 11;
};

export const zipCodeValid = value => {
  const qty = value.replace(/\D/g, '');
  if (qty.length > 8) return false;
  return /\d{5}-\d{3}/g.test(value);
};
