import React, { useMemo } from 'react';
import { Card, CardBody } from '../../../../partials/controls';
import { SubasideShort } from '../../../components/subaside-short/Subaside';
import DepositBatchForm from './form/DepositBatchForm';
import { useHtmlClassService } from '../../../_core/MetronicLayout';

import { billetBatchStore } from '../../../../store/Batch';

const DepositPage = props => {
  const uiService = useHtmlClassService();
  const { currentBillet } = billetBatchStore(store => store.state);

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <div className="row">
      <SubasideShort />

      <Card className="col-xs-9 col-xl-9 col-md-9">
        <CardBody>
          {currentBillet > 0 ? (
            <DepositBatchForm layoutProps={layoutProps} />
          ) : (
            <div>Nenhum lote gerado</div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DepositPage;
