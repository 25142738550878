import React from 'react';
import { Card, CardBody } from '../../../../partials/controls';
import { Subaside } from '../../../components/subaside/Subaside';
import DepositTable from './list/DepositBatchList';

const DepositBatchList = () => {
  return (
    <div className="row">
      <Subaside />

      <Card className="col-xs-8 col-xl-8 col-md-8">
        <CardBody>
          <div className="row">
            <div className="col-md-10">
              <h4>Visualização de Lote</h4>
              <p className="text-muted mt-1">Acompanhe seus boletos em lote</p>
            </div>
          </div>
          <DepositTable />
        </CardBody>
      </Card>
    </div>
  );
};

export default DepositBatchList;
