import React from "react";

export function NewBank(props) {
	return (<>
		{(() => {
			return props.banks.map((v, i) => {
				return (
					<div key={i} className="form-group fv-plugins-icon-container">
						<label className="text-muted">Banco:</label>
						<select
							type="text"
							className="form-control h-auto py-4 px-6"
							name="patrimony"
						>
							<option value={v.id} selected>{v.name}</option>
						</select>
						<button className="btn text-danger pt-3"
							onClick={() => {
								props.removeBank(v.id)
							}}
						>
							<i className="fa fa-times text-danger"></i>
							&nbsp;Remover banco
						</button>
					</div>
				)
			})
		})()}
	</>);
}
