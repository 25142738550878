import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../Transfer/_redux/external/actions';
import { useFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { Card, CardBody } from '../../../../partials/controls';
import { CardMessage } from '../../../components/message/CardMessage';
import { ButtonCustom } from '../../../components/button/ButtonCustom';
import ErrorFields from '../../../components/alert/ErrorFields';
import { Subaside } from '../../../components/subaside/Subaside';

import { useHtmlClassService } from '../../../_core/MetronicLayout';
import {
  moneyMask,
  removeCurrencyMask,
  formatPrice,
} from '../../../utils/currency.utils';

import { ENDPOINT } from '../../../../services/enums/EndPoint';
import axios from 'axios';
import Clipboard from 'clipboard-copy';
import QRCode from 'react-qr-code';

function PixReceber(props) {
  const { user, error_fields, fetchError } = props;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [value, setValue] = useState(0);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  // Inicializando e validando formulário
  const formik = useFormik({
    initialValues: {
      value: 'R$ 0,00',
      description: '',
    },
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const data = {
        value: parseFloat(removeCurrencyMask(values.value)),
      };

      if (values.description !== '') data.description = values.description;

      if (parseFloat(data.value) <= 0) {
        alert('Valor precisa ser maior que 0!');
        return false;
      }

      setValue(data.value);

      setLoading(true);
      // let str =
      //   'MDAwMjAxMDEwMjEyMjY4MzAwMTRCUi5HT1YuQkNCLlBJWDI1NjFxcmNvZGUtaC5iYW5jb2FyYmkuY29tLmJyL2dpLzQ0NmMxYTUwYWU1MDRmYTNiNGU4ZjE1YThhY2FjZmE3NTIwNDAwMDA1MzAzOTg2NTgwMkJSNTkyNVRFQ0hORVRJVU0gQlJBU0lMIFBBUlRJQ0k2MDA5U0FPIFBBVUxPNjIwNzA1MDMqKio2MzA0NzM4Qg==';
      // setCode(atob(str));

      // setLoading(false);

      // setTimeout(() => {
      try {
        axios
          .post(`${ENDPOINT.PIX_QR_CODE}`, data)
          .then(responseSuccess => {
            setCode(atob(responseSuccess.data.encoded_content));

            setLoading(false);
          })
          .catch(responseError => {
            console.log(responseError);
            setLoading(false);
          });
        // generateTransfer(data);
      } catch (error) {
        console.log(error);
        setSubmitting(false);
        setLoading(false);
      }
      // }, 1000);
    },
  });

  const useStyles = makeStyles(theme => ({
    container: {
      marginBottom: '25px',
      marginTop: '40px',
      flexDirection: 'row',
      display: 'flex',
    },
    left: {
      width: '20%',
    },
    right: {
      width: 'auto',
    },
    userData: {
      backgroundColor: '#E1E1E8',
      paddingTop: 20,
      paddingBottom: 20,
      marginRight: 20,
      borderRadius: 15,
    },
    userDataRow: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      display: 'flex',
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 5,
    },
    textLeft: {
      marginRight: 20,
    },
  }));

  const classes = useStyles();

  return (
    <div className="row">
      <Subaside />
      {/** begin::ModalError */}
      <ErrorFields
        error_fields={error_fields}
        show={error_fields !== '' ? true : false}
        onHide={() => {
          fetchError('');
          setLoading(false);
        }}
      />
      {/** end::ModalError */}
      <div className="col-md-8 col-xs-12 col-lg-8">
        <CardMessage message="Tarifa de PIX R$ 0,00.">
          <div>
            <br />
            Preencha as informações ou continue para receber seu pagamento
          </div>
        </CardMessage>

        <Card className="col-xs-12 col-xl-12 col-md-12">
          <CardBody>
            {code === '' ? (
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  {/** begin::Valor*/}
                  <div className="col-md-6 col-lg-6 col-xs-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label htmlFor="value">Valor</label>
                      <input
                        placeholder="R$ 0,00"
                        type="text"
                        className="form-control h-auto py-3 px-6"
                        name="value"
                        {...formik.getFieldProps('value')}
                        onChange={e => {
                          formik.setFieldValue(
                            'value',
                            moneyMask(e.target.value)
                          );
                        }}
                      />
                      {formik.touched.value && formik.errors.value ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.value}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/** end::Valor*/}
                  {/** begin::Chave Selecionada*/}
                  <div className="col-md-6 col-lg-6 col-xs-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label htmlFor="document">Descrição(opcional)</label>
                      <input
                        type="text"
                        className="form-control h-auto py-3 px-6"
                        name="document"
                        {...formik.getFieldProps('document')}
                        onChange={e => {
                          formik.setFieldValue('document', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/** end::Chave Selecionada*/}
                </div>

                <div className="row">
                  <div className="col-md-12 col-md-offset-2">
                    <ButtonCustom
                      disabled={formik.values.value === 'R$ 0,00' || loading}
                      type="submit"
                      layoutProps={layoutProps}
                    >
                      Continuar
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </ButtonCustom>
                  </div>
                </div>
              </form>
            ) : (
              <div className="row">
                <div className="col-md-12 col-md-offset-2">
                  <div className="col-md-12">
                    <span>QRCode gerado com sucesso!</span> <br />
                    <div className={classes.container}>
                      <div className={classes.left}>
                        <QRCode value={code} size={128} />
                      </div>

                      <div className={classes.right}>
                        <span>Confirme os dados do destinatário:</span>

                        <div className={classes.userData}>
                          <div className={classes.userDataRow}>
                            <span className={classes.textLeft}>Valor</span>
                            <span>{formatPrice(value)}</span>
                          </div>
                          <div className={classes.userDataRow}>
                            <span className={classes.textLeft}>Nome</span>
                            <span>{user.name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ButtonCustom
                  onClick={() => Clipboard(code)}
                  layoutProps={layoutProps}
                >
                  Copiar código
                </ButtonCustom>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default injectIntl(
  connect(
    ({ external, auth }) => ({
      banks: external.banks,
      message: external.message,
      error_fields: external.error_fields,
      user: auth.user,
    }),
    actions.actions
  )(PixReceber)
);
