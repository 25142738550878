import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import * as auth from '../Auth/_redux/authRedux';
import * as fromRegister from './_redux/actions';
import * as fromPhysical from './PhysicalPerson/_redux/actions';
import * as fromLegal from './LegalPerson/_redux/actions';
import * as fromPhone from './Verification/PhoneNumber/_redux/actions';
import * as fromZipCode from './Verification/ZipCode/_redux/actions';

import { useHtmlClassService } from '../../../components';
import { PhysicalForm } from './PhysicalPerson';
import { LegalForm } from './LegalPerson';
import { ButtonSelector } from '../../../components/button/ButtonSelector';
import { bindActionCreators } from 'redux';

function Register(props) {
  const [personType, setTypePerson] = useState(true);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-15">
        <h4 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h4>
      </div>

      {/* begin: Tipo de conta */}
      <div className="form-group fv-plugins-icon-container">
        <label className="text-muted">
          <FormattedMessage id="REGISTER.LABEL.ACCOUNT_TYPE" />
        </label>

        <div className="row ml-4">
          <ButtonSelector
            layoutProps={layoutProps}
            type="button"
            onClick={() => {
              setTypePerson(true);
              props.actions.clearLegalPerson();
              props.actions.clearPhone();
              props.actions.clearZipCode();
              props.actions.clearDocuments();
            }}
            hover={personType}
            className="btn font-weight-bold px-6 py-2 my-2"
            size="sm"
          >
            <FormattedMessage id="REGISTER.ACCOUNT_TYPE.PHYSICAL" />
          </ButtonSelector>

          <ButtonSelector
            layoutProps={layoutProps}
            type="button"
            onClick={() => {
              setTypePerson(false);
              props.actions.clearPhysicalPerson();
              props.actions.clearPhone();
              props.actions.clearZipCode();
              props.actions.clearDocuments();
            }}
            hover={personType ? false : true}
            className="btn font-weight-bold px-6 py-2 my-2"
            size="sm"
          >
            <FormattedMessage id="REGISTER.ACCOUNT_TYPE.LEGAL" />
          </ButtonSelector>
        </div>
      </div>
      {/* end: Tipo de conta */}

      {(() => {
        return personType ? <PhysicalForm /> : <LegalForm />;
      })()}
    </div>
  );
}

export default injectIntl(
  connect(
    null,

    dispatch => {
      return {
        actions: bindActionCreators(
          Object.assign(
            {},
            auth.actions,
            fromPhysical.actions,
            fromLegal.actions,
            fromPhone.actions,
            fromZipCode.actions,
            fromRegister.actions
          ),
          dispatch
        ),
      };
    }
  )(Register)
);
