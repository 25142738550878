import { put, takeLatest } from "redux-saga/effects";
import { initialState, types, actions } from "./actions";
import { getSummary, getBalance } from "./crud";
import { currency } from "../../app/utils/currency.utils";
import { dataChartFormatted } from "../../app/utils/apexchart.utils";


export const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case types.SummaryLoaded: {
            const { summary } = action.payload;
            
            state.summary.series = [
                {
                    name: 'Crédito',
                    data: summary.credit
                },
                {
                    name: 'Débito',
                    data: summary.debit
                }
            ]
            
            state.summary.xaxis.categories = summary.keys;

            return { 
              ...state
            };
        }

        case types.BalanceLoaded: {
            const { balance } = action.payload;
            balance.balance = currency(balance.balance);
            return { ...state, balance: balance };
        }

      default:
        return state;
    }
}


export function* saga() {
    yield takeLatest(types.SummaryRequested, function* summaryRequested() {
      try {  
        const { data } = yield getSummary();
        const options = yield dataChartFormatted(data);
        yield put(actions.fetchSummary(options));
      } catch (error) {
        console.log(error)
      }
    });

    yield takeLatest(types.BalanceRequested, function* balanceRequested() {
        try {  
          const { data } = yield getBalance();
          yield put(actions.fetchBalance(data));
        } catch (error) {
          console.log(error)
        }
    });
  }