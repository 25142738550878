import React, { useEffect, useState } from 'react';

import { Subaside } from '../../components/subaside/Subaside';
import { Card, CardBody } from '../../../partials/controls';

import UserServices from '../../../services/UserServices';

import { dateFormatted } from '../../utils/date.utils';

export function Notificacoes({ history }) {
  const [notificacoes, setNotificacoes] = useState([]);
  
  const loadNotifications = async () => {
    let info = await UserServices.getNotifications();
    setNotificacoes(info.data.results);
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  return (
    <div className="row">
      <Subaside />
      <div className="col-md-8 col-xs-12 col-lg-8">
        <Card className="col-xs-12 col-xl-12 col-md-12">
          <CardBody>
            <h4>Notificações</h4>

            {notificacoes && notificacoes.length === 0 && <p>Sem notificações</p>}

            {notificacoes &&
              notificacoes.map((not, index) => {
                return (
                  <div key={index} style={{
                    marginBottom: '15px',
                    borderBottom: '1px solid #efefef',
                    marginTop: '15px',
                  }}>
                    <div className="navi-text">
                      <div className="text-muted text-left">
                      <span>{dateFormatted(not.created_at.split(' ')[0])} {not.created_at.split(' ')[1]}</span> <br />
                        <span style={{
                          fontWeight: 'bold'
                        }}>{not.data.title}</span> <br />
                        <span>{not.data.description}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
