import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./ProfileUIHelpers";

const ProfileUIContext = createContext();

export function useProfileUIContext() {
  return useContext(ProfileUIContext);
}

export const ProfileUIConsumer = ProfileUIContext.Consumer;

export function ProfileUIProvider({ profileUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initProfile = {
    email: "",
    address: {
      country: "",
      state: "",
      city: "",
      zip_code: null,
      district: "",
      street_name: "",
      number: null,
      complement: ""
    }
  }

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initProfile,
    openFetchProfileDialog: profileUIEvents.openFetchProfileDialog
  };

  return <ProfileUIContext.Provider value={value}>{children}</ProfileUIContext.Provider>;
}