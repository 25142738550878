export const BillStatusCssClasses = {
  pending: "warning", 
  liquidated: "success"
};
export const BillStatusTitles = ["Suspended", "Active", "Pending", ""];
export const BillTypeCssClasses = ["success", "primary", ""];
export const BillTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];

export const initialFilter = {
  filter: {
    favored: "",
    status: "",
    value: "",
    created_at: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 15
};

export const initialValues = {
  type_payment: '',
  writable_line: "",
	bar_code: "",
	value_origin: null,
	value: '',
	due_date: "",
	discount: 0,
	addition: 0
}

