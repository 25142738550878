export const initialState = {
    password: {}
}

export const types = {
    PasswordRegister: "[Register] Password",
    PasswordRegisterLoaded: "[Loaded] Password"
}

export const actions = {
    registerPassword: register => ({ 
        type: types.PasswordRegister, 
        payload: { register } 
    }),
    loadPassword: register => ({ 
        type: types.PasswordRegisterLoaded, 
        payload: { register } 
    })
}