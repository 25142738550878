export const appStoreURI = () => {
  let uris = URIS['1'];
  return uris.ios;
};

export const playStoreURI = () => {
  let uris = URIS['1'];
  return uris.android;
};

export const URIS = Object.freeze({
  '1': {
    ios: '',
    android: '',
  },
});
