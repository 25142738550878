import React, { Suspense, useMemo, useState } from "react";
import { Switch, Link, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";

import { LayoutSplashScreen, ContentRoute, useHtmlClassService } from "../../../components";
import Register from "./Register";
import { PhysicalPersonalForm, PhysicalFinancialForm, PhysicalDocumentFiles } from "./PhysicalPerson";
import { LegalInformation, LegalFinancialForm } from "./LegalPerson";

import {
  PhoneVerification,
  ZipCodeVerification,
  BankAccountVerification,
  PasswordVerification
} from "./Verification";
import { toAbsoluteUrl } from "../../../../helpers";
import Main from "./Documents/Main";
import { Progress } from "../../../components/progress/Progress";
import ContainerOverflow from "../../../components/Container/ContainerOverflow";

export default function RegisterPage() {
  const [percent, setPercent] = useState(0);
  const uiService = useHtmlClassService();
  const history = useHistory();
  
  const layoutProps = useMemo(() => {
    return {
      bg_login: uiService.getBgLogin(),
      logo: uiService.getLogo(),
      colors: uiService.getColors()
    };
  }, [uiService]);

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        <div
          className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat pt-10 p-lg-10"
          style={{
            backgroundImage: `url(${layoutProps.bg_login})`,
            backgroundPosition: "center"
          }}
        >
          <div className="d-flex flex-row-fluid pt-10 flex-column justify-content-between">
            <div className="flex-column-fluid d-flex align-items-center flex-column justify-content-center">
              <Link to="/" className="flex-column-auto">
                <img
                  alt="Logo"
                  className="max-h-70px center"
                  src={layoutProps.logo}
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="bg-white flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          <div className="row">
            <div className="col-md-2 
            flex-column-auto justify-content-center py-5 px-10">
              <Link
                to=""
                onClick={history.goBack}
              >
                <span className="svg-icon svg-icon-1x svg-icon-primary mt-4">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Media/Back.svg")}
                  ></SVG>
                </span>
                &nbsp;Voltar
              </Link>
            </div>
            {(() => {
              if(history.location.pathname !== '/cadastro'
              && history.location.pathname.split('/')[2] !== ('documentos' || 'conta-fisica')) {
                return (
                  <div className="mt-5 mb-20 col-md-8 col-lg-8 col-xs-12">
                    <Progress percent={percent} />
                  </div>
                )
              }
            })()}
          </div>
          {/*end::Content header*/}
          <ContainerOverflow>
            <div className="d-flex flex-center mt-lg-0">
              <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                  {/** Conta juridica */}
                  <ContentRoute 
                    exact={true}
                    path="/cadastro/conta-juridica" 
                    component={() => <LegalInformation setPercent={setPercent} />}
                  />
                  <ContentRoute 
                    exact={true}
                    path="/cadastro/conta-juridica/financeiro" 
                    component={() => <LegalFinancialForm setPercent={setPercent} />}
                  />

                  {/** Conta fisica */}
                  <ContentRoute
                    exact={true}
                    path="/cadastro/conta-fisica"
                    component={() => <PhysicalPersonalForm setPercent={setPercent} />}
                  />
                  <ContentRoute
                    exact={true}
                    path="/cadastro/conta-fisica/financeiro"
                    component={() => <PhysicalFinancialForm setPercent={setPercent} />}
                  />
                  <ContentRoute
                    exact={true}
                    path="/cadastro/conta-fisica/documento"
                    component={() => <PhysicalDocumentFiles setPercent={setPercent} />}
                  />

                  {/** Rotas de Verificações */}
                  <ContentRoute 
                    path="/cadastro/telefone" 
                    component={() => <PhoneVerification setPercent={setPercent} />} 
                  />
                  <ContentRoute 
                    exact={true} 
                    path="/cadastro/endereco" 
                    component={() => <ZipCodeVerification setPercent={setPercent} /> } />
                  <ContentRoute 
                    exact={true} 
                    path="/cadastro/bancos" 
                    component={() => <BankAccountVerification setPercent={setPercent} />} />
                  <ContentRoute 
                    exact={true} 
                    path="/cadastro/senha" 
                    component={() => <PasswordVerification setPercent={setPercent} />} />
                  <ContentRoute 
                    exact={true} 
                    path="/cadastro/documentos" 
                    component={() => <Main setPercent={setPercent} />} />
                  <ContentRoute 
                    path="/cadastro" 
                    component={Register} />
                </Switch>
              </Suspense>
            </div>
          </ContainerOverflow>
        </div>
      </div>
    </div>
  );
}
