import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../app/components";
import { DashBoard } from "./pages/DashBoard";
import { Notificacoes } from "./pages/Notificacoes";
// Modules
import MyAccount from "./pages/MyAccount/MyAccountPage";
import Transfer from "./pages/Transfer/TransferPage";
import Payment from "./pages/Payment/PaymentPage";
import Card from "./pages/Card/";
import OtherService from "./pages/OtherService/OtherServicePage";
import { SecurityTransactionRegister } from "./pages/SecurityTransaction/SecurityTransactionRegister";
import { useSelector } from "react-redux";
// - - -

export default function BasePage() {
  const { device } = useSelector(({auth}) => ({ device: auth.device }))
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redireciona para a página inicial */
          <Redirect exact={true} from="/" to="/dashboard" />
        }
        <ContentRoute path="/conta" component={MyAccount} />
        <ContentRoute path="/dashboard" component={DashBoard} />
        <ContentRoute path="/notificacoes" component={Notificacoes} />

        {/** Exibe modal informando que submódulos ainda não existem */}
        <Route path="/cartao">
          {({ history, match }) => (
            <Card
              show={match != null}
              onHide={() => {
                history.push("/");
              }}
            />
          )}
        </Route>
        {/** - - - */}

        {(device.device_id === null) ? (
          <ContentRoute 
            path={[
              "/transferencia",
              "/pagamento",
              "/servicos"
            ]} 
            component={SecurityTransactionRegister} 
          />
        ) : (
            <ContentRoute>
              <ContentRoute path="/transferencia" component={Transfer} />
              <ContentRoute path="/pagamento" component={Payment} />
              <ContentRoute path="/servicos" component={OtherService} />
            </ContentRoute>
          )
        } 
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}

