export const initialState = {
    initialized: false,
    basic: {},
    personal: {},
    financial: {}
}

export const types = {
    LegalRegister: "[Register] Legal Register",
    LegalRegisterLoaded: "[Loaded] Legal Register",
    LegalPersonalRegister: "[Register] Legal Personal",
    LegalPersonalRegisterLoaded: "[Loaded] Legal Personal",
    LegalFinancialRegister: "[Register] Legal Financial",
    LegalFinancialRegisterLoaded: "[Loaded] Legal Financial",
    ClearLegal: "[Clear] Legal"
}

export const actions = {
    registerBasicInformation: register => ({ 
        type: types.LegalRegister, 
        payload: { register } 
    }),
    loadBasicInformation: register => ({ 
        type: types.LegalRegisterLoaded, 
        payload: { register } 
    }),
    registerPersonalInformation: register => ({ 
        type: types.LegalPersonalRegister, 
        payload: { register } 
    }),
    loadPersonalInformation: register => ({ 
        type: types.LegalPersonalRegisterLoaded, 
        payload: { register } 
    }),
    registerFinancialInformation: register => ({ 
        type: types.LegalFinancialRegister, 
        payload: { register } 
    }),
    loadFinancialInformation: register => ({ 
        type: types.LegalFinancialRegisterLoaded, 
        payload: { register } 
    }),
    clearLegalPerson: register => ({ 
        type: types.ClearLegal, 
        payload: { register } 
    })
}