import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';

import { Card, CardBody } from '../../../../../partials/controls';
import { CardMessage } from '../../../../components/message/CardMessage';
import Select from 'react-select';
import ErrorFields from '../../../../components/alert/ErrorFields';
import {
  moneyMask,
  documentMask,
  removeCurrencyMask,
  onlyNumbers,
} from '../../../../utils/currency.utils';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';

import { cpfValid, cnpjValid } from '../../../../utils/valid.utils';

import { billetBatchStore } from '../../../../../store/Batch';
import { useEffect } from 'react';

function LoteForm(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [errorFields, setErrorFields] = useState('');
  const [initialData, setInitialData] = useState({
    email: '',
    payer: '',
    document: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    district: '',
    street_name: '',
    zipcode: '',
    value: '',
    installments: '',
  });

  const [itens, setItems] = useState([]);
  const [ready, setReady] = useState(false);

  const [selectedInstallment, setSelectedInstallment] = useState();

  const { billets, currentBillet } = billetBatchStore(store => store.state);
  const { updateBillets } = billetBatchStore(store => store.actions);

  useEffect(() => {
    if (billets[currentBillet - 1][0]?.payer?.email) {
      setInitialData({
        email: billets[currentBillet - 1][0].payer.email,
        payer: billets[currentBillet - 1][0].payer.name,
        document: billets[currentBillet - 1][0].payer.document,
        number: billets[currentBillet - 1][0].payer.number,
        complement: billets[currentBillet - 1][0].payer.complement,
        city: billets[currentBillet - 1][0].payer.city,
        state: billets[currentBillet - 1][0].payer.state,
        district: billets[currentBillet - 1][0].payer.district,
        street_name: billets[currentBillet - 1][0].payer.street_name,
        zipcode: billets[currentBillet - 1][0].payer.zipcode,
        value:
          billets[currentBillet - 1][0].value *
          billets[currentBillet - 1].length,
        installments: billets[currentBillet - 1].length,
      });
      setSelectedInstallment({
        label: parseInt(billets[currentBillet - 1].length),
        value: parseInt(billets[currentBillet - 1].length),
      });
      updateInstallments(
        billets[currentBillet - 1].currentValue,
        billets[currentBillet - 1].length
      );
      setReady(true);
      let obj = [];
      for (let x in billets[currentBillet - 1]) {
        obj.push({
          value:
            billets[currentBillet - 1][x].currentValue /
            billets[currentBillet - 1].length,
          due_date: billets[currentBillet - 1][x].due_date,
          fine_type: billets[currentBillet - 1][x].fine_type,
          fine_value: billets[currentBillet - 1][x].fine_value,
          fine_start_date: billets[currentBillet - 1][x].fine_start_date,
          mora_type: billets[currentBillet - 1][x].mora_type,
          mora_value: billets[currentBillet - 1][x].mora_value,
          mora_start_date: billets[currentBillet - 1][x].mora_start_date,
        });
      }

      setItems(obj);
    } else {
      setInitialData({
        email: '',
        payer: '',
        document: '',
        number: '',
        complement: '',
        city: '',
        state: '',
        district: '',
        street_name: '',
        zipcode: '',
        value: '',
        installments: '',
      });
      updateInstallments(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billets, currentBillet]);

  // Schema de validação dos campos
  const ExternaTransferSchema = Yup.object().shape({
    email: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    value: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    payer: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    document: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .test('Document Test', 'O número do documento esta inválido', value => {
        if (value) {
          const numbers = value.replace(/\D/g, '');
          if (numbers.length <= 11) {
            return cpfValid(value);
          } else {
            return cnpjValid(value);
          }
        }
      }),
    zipcode: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .test('Document Test', 'O CEP está incorreto', value => {
        if (value) {
          value = value.toString();
          // console.log(value);
          // const numbers = value.replace(/\D/g, '');
          // console.log(value, typeof value);
          return value.length === 8;
          //   return zipCodeValid(value);
          // }
        }
      }),
    street_name: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    number: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    complement: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    city: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    state: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    district: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    installments: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const installments = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
    {
      label: 6,
      value: 6,
    },
    {
      label: 7,
      value: 7,
    },
    {
      label: 8,
      value: 8,
    },
    {
      label: 9,
      value: 9,
    },
    {
      label: 10,
      value: 10,
    },
    {
      label: 11,
      value: 11,
    },
    {
      label: 12,
      value: 12,
    },
  ];

  const fineTypes = [
    {
      label: 'Sem Multa',
      value: 'free',
    },
    {
      label: 'Absoluto',
      value: 'absolut',
    },
    {
      label: 'Percentual',
      value: 'percentage',
    },
  ];

  const moraTypes = [
    {
      label: 'Sem Mora',
      value: 'free',
    },
    {
      label: 'Valor Diário',
      value: 'day_value',
    },
    {
      label: 'Porcentagem Mensal',
      value: 'monthly_percentage',
    },
    {
      label: 'Padrão Bancário',
      value: 'bank_default',
    },
  ];

  const updateInstallments = (valor, param) => {
    let newItens = [];
    setLoading(true);

    for (let i = 1; i <= param; i++) {
      newItens.push({
        value:
          param > 0
            ? removeCurrencyMask(valor) / param
            : removeCurrencyMask(valor),
        due_date: '',
        fine_type: {
          label: 'Sem Multa',
          value: 'free',
        },
        fine_value: 0,
        fine_start_date: '',
        mora_type: {
          label: 'Sem Mora',
          value: 'free',
        },
        mora_value: 0,
        mora_start_date: '',
      });
    }

    setItems(newItens);
    setLoading(false);
  };

  const updateValues = (index, field, value) => {
    let current = itens;

    if (field === 'mora_type') {
      if (value === 'free')
        value = {
          label: 'Sem Mora',
          value: 'free',
        };
      else if (value === 'day_value')
        value = {
          label: 'Valor Diário',
          value: 'day_value',
        };
      else if (value === 'monthly_percentage')
        value = {
          label: 'Porcentagem Mensal',
          value: 'monthly_percentage',
        };
      else if (value === 'bank_default')
        value = {
          label: 'Padrão Bancário',
          value: 'bank_default',
        };
    }

    if (field === 'fine_type') {
      if (value === 'free')
        value = {
          label: 'Sem Multa',
          value: 'free',
        };
      else if (value === 'absolut')
        value = {
          label: 'Absoluto',
          value: 'absolut',
        };
      else if (value === 'percentage')
        value = {
          label: 'Percentual',
          value: 'percentage',
        };
    }

    current[index][field] = value;
    setItems([...current]);
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: ExternaTransferSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(values);

      setReady(true);
      updateInstallments(removeCurrencyMask(values.value), values.installments);
    },
  });

  const removerAcentos = newStringComAcento => {
    var string = newStringComAcento;
    var mapaAcentosHex = {
      a: /[\xE0-\xE6]/g,
      A: /[\xC0-\xC6]/g,
      e: /[\xE8-\xEB]/g,
      E: /[\xC8-\xCB]/g,
      i: /[\xEC-\xEF]/g,
      I: /[\xCC-\xCF]/g,
      o: /[\xF2-\xF6]/g,
      O: /[\xD2-\xD6]/g,
      u: /[\xF9-\xFC]/g,
      U: /[\xD9-\xDC]/g,
      c: /\xE7/g,
      C: /\xC7/g,
      n: /\xF1/g,
      N: /\xD1/g,
    };

    for (var letra in mapaAcentosHex) {
      var expressaoRegular = mapaAcentosHex[letra];
      string = string.replace(expressaoRegular, letra);
    }

    return string;
  };

  const updateBatch = () => {
    const current = [...billets];
    // console.log(itens);

    let array = [];
    for (let i in itens) {
      array.push({
        payer: {
          name: formik.values.payer,
          document: formik.values.document.replace(/\D/g, ''),
          zipcode: formik.values.zipcode,
          street_name: removerAcentos(formik.values.street_name),
          number: formik.values.number,
          complement: formik.values.complement,
          district: formik.values.district,
          state: formik.values.state,
          city: formik.values.city,
          email: formik.values.email,
        },
        currentValue: itens[i].value * itens.length,
        value: itens[i].value,
        due_date: itens[i].due_date,
        fine_type: itens[i].fine_type,
        fine_value: itens[i].fine_value,
        fine_start_date: itens[i].fine_start_date,
        mora_type: itens[i].mora_type,
        mora_value: itens[i].mora_value,
        mora_start_date: itens[i].mora_start_date,
      });
    }
    current[currentBillet - 1] = array;

    updateBillets([...current]);

    formik.resetForm();
    updateInstallments(0, 0);
  };

  return (
    <>
      {/** begin::ModalError */}
      <ErrorFields
        error_fields={errorFields}
        show={errorFields !== '' ? true : false}
        onHide={() => {
          setLoading(false);
          setErrorFields('');
        }}
      />
      {/** end::ModalError */}
      <CardMessage message="Preencha corretamente os campos abaixo para criar um Boleto em Lote">
        <div style={{ fontSize: 16 }}>Lote {currentBillet || ''}</div>
      </CardMessage>

      <Card className="col-xs-12 col-xl-12 col-md-12">
        <CardBody>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              {/** begin::Título*/}
              <div className="col-md-3 col-lg-3 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="email">E-mail:</label>
                  <input
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="email"
                    {...formik.getFieldProps('email')}
                    onChange={e => {
                      formik.setFieldValue('email', e.target.value);
                    }}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Título*/}

              {/** begin::Pagador*/}
              <div className="col-md-3 col-lg-3 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="payer">Pagador:</label>
                  <input
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="payer"
                    {...formik.getFieldProps('payer')}
                    onChange={e => {
                      formik.setFieldValue('payer', e.target.value);
                    }}
                  />
                  {formik.touched.payer && formik.errors.payer ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.payer}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Pagador*/}

              {/** begin::Valor*/}
              <div className="col-md-3 col-lg-3 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="value">Valor:</label>
                  <input
                    placeholder="R$ 0,00"
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="value"
                    {...formik.getFieldProps('value')}
                    onChange={e => {
                      formik.setFieldValue('value', moneyMask(e.target.value));
                    }}
                  />
                  {formik.touched.value && formik.errors.value ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.value}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Valor*/}

              {/** begin::Parcelas*/}
              <div className="col-md-3 col-lg-3 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="value">Parcelas:</label>
                  <Select
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: 'none',
                      },
                    })}
                    name="installments"
                    placeholder="-- Selecione --"
                    options={installments}
                    onChange={e => {
                      formik.setFieldValue('installments', e.value);

                      let installm = installments.filter(
                        ins => ins.value === e.value
                      );
                      setSelectedInstallment(installm[0]);
                      updateInstallments(
                        formik.values.value,
                        installm[0].value
                      );
                    }}
                    value={selectedInstallment}
                  />
                  {formik.touched.installments && formik.errors.installments ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.installments}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Parcelas*/}

              {/** begin::CPF_ou_CNPJ*/}
              <div className="col-md-4 col-lg-4 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="document">CPF ou CNPJ:</label>
                  <input
                    maxLength="18"
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="document"
                    {...formik.getFieldProps('document')}
                    onChange={e => {
                      formik.setFieldValue(
                        'document',
                        documentMask(e.target.value)
                      );
                    }}
                  />
                  {formik.touched.document && formik.errors.document ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.document}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::CPF_ou_CNPJ*/}

              {/** begin::Endereço*/}
              <div className="col-md-6 col-lg-6 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="street_name">Endereço:</label>
                  <input
                    placeholder=""
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="street_name"
                    {...formik.getFieldProps('street_name')}
                    onChange={e => {
                      formik.setFieldValue('street_name', e.target.value);
                    }}
                  />
                  {formik.touched.street_name && formik.errors.street_name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.street_name}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Endereço*/}

              {/** begin::Conta*/}
              <div className="col-md-2 col-lg-2 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="number">Número:</label>
                  <input
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="number"
                    {...formik.getFieldProps('number')}
                    onChange={e => {
                      formik.setFieldValue(
                        'number',
                        onlyNumbers(e.target.value, 15)
                      );
                    }}
                  />
                  {formik.touched.number && formik.errors.number ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.number}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Conta*/}

              {/** begin::ZipCode*/}
              <div className="col-md-2 col-lg-2 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="zipcode">CEP: (Números)</label>
                  <input
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="zipcode"
                    {...formik.getFieldProps('zipcode')}
                    onChange={e => {
                      formik.setFieldValue(
                        'zipcode',
                        onlyNumbers(e.target.value, 15)
                      );
                    }}
                  />
                  {formik.touched.zipcode && formik.errors.zipcode ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.zipcode}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Complemento*/}

              {/** begin::Complemento*/}
              <div className="col-md-4 col-lg-4 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="complement">Complemento:</label>
                  <input
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="complement"
                    {...formik.getFieldProps('complement')}
                    onChange={e => {
                      formik.setFieldValue('complement', e.target.value);
                    }}
                  />
                  {formik.touched.complement && formik.errors.complement ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.complement}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::ZipCode*/}

              {/** begin::Cidade*/}
              <div className="col-md-2 col-lg-2 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="city">Cidade:</label>
                  <input
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="city"
                    {...formik.getFieldProps('city')}
                    onChange={e => {
                      formik.setFieldValue('city', e.target.value);
                    }}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.city}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Cidade*/}

              {/** begin::Estado*/}
              <div className="col-md-2 col-lg-2 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="state">Estado:</label>
                  <input
                    type="text"
                    maxLength={2}
                    className="form-control h-auto py-3 px-6"
                    name="state"
                    {...formik.getFieldProps('state')}
                    onChange={e => {
                      formik.setFieldValue('state', e.target.value);
                    }}
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.state}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Estado*/}

              {/** begin::Bairro*/}
              <div className="col-md-2 col-lg-2 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="district">Bairro:</label>
                  <input
                    type="text"
                    className="form-control h-auto py-3 px-6"
                    name="district"
                    {...formik.getFieldProps('district')}
                    onChange={e => {
                      formik.setFieldValue('district', e.target.value);
                    }}
                  />
                  {formik.touched.district && formik.errors.district ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.district}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Bairro*/}
            </div>

            {(ready === false || itens.length === 0) && (
              <div className="row">
                <div className="col-md-12 col-md-offset-2">
                  <ButtonCustom layoutProps={props.layoutProps}>
                    Continuar
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </ButtonCustom>
                </div>
              </div>
            )}
          </form>

          {ready === true &&
            itens &&
            itens.map((item, index) => {
              return (
                <div
                  style={{
                    border: '1px solid #B5B5C3',
                    padding: 5,
                    marginBottom: 15,
                    borderRadius: 10,
                  }}
                >
                  <span>
                    <strong>
                      Parcela {index + 1}
                      {index >= 2 && ' - Intervalo máximo de 15 dias'}
                    </strong>
                  </span>
                  <div className="row">
                    {/* Inicio Valor */}
                    <div className="col-md-2 col-lg-2 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="value">Valor:</label>
                        <input
                          disabled
                          type="text"
                          className="form-control h-auto py-3 px-6"
                          name="value"
                          value={moneyMask(item.value * 100)}
                        />
                      </div>
                    </div>
                    {/* Fim Valor */}

                    {/* Inicio Vencimento */}
                    <div className="col-md-3 col-lg-3 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="value">Vencimento:</label>
                        <input
                          type="date"
                          className="form-control h-auto py-3 px-6"
                          name="due_date"
                          value={item.due_date}
                          onChange={e => {
                            updateValues(index, 'due_date', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* Fim Vencimento */}

                    {/* Inicio Tipo de Multa */}
                    <div className="col-md-3 col-lg-3 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="value">Tipo de Multa:</label>
                        <Select
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'none',
                            },
                          })}
                          name="fine_type"
                          placeholder="-- Selecione --"
                          options={fineTypes}
                          onChange={e => {
                            updateValues(index, 'fine_type', e.value);
                            console.log(e.value);
                            if (e.value === 'free') {
                              updateValues(index, 'fine_value', 0);
                              updateValues(index, 'fine_start_date', '');
                            }
                          }}
                          value={item.fine_type}
                        />
                      </div>
                    </div>
                    {/* Fim Tipo de Multa */}

                    {/* Inicio Valor da Multa */}
                    <div className="col-md-2 col-lg-2 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="fine_value">Valor da Multa:</label>
                        <input
                          disabled={item.fine_type.value === 'free'}
                          type="number"
                          className="form-control h-auto py-3 px-6"
                          name="fine_value"
                          value={item.fine_value}
                          onChange={e => {
                            updateValues(index, 'fine_value', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* Fim Valor da Multa */}

                    {/* Inicio Data de Cobrança Multa */}
                    <div className="col-md-3 col-lg-3 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="value">Data de Cobrança Multa:</label>
                        <input
                          disabled={item.fine_type.value === 'free'}
                          type="date"
                          className="form-control h-auto py-3 px-6"
                          name="fine_start_date"
                          value={item.fine_start_date}
                          onChange={e => {
                            updateValues(
                              index,
                              'fine_start_date',
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* Fim Data de Cobrança Multa */}

                    {/* Inicio Tipo de Mora */}
                    <div className="col-md-3 col-lg-3 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="value">Tipo de Mora:</label>
                        <Select
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'none',
                            },
                          })}
                          name="mora_type"
                          placeholder="-- Selecione --"
                          options={moraTypes}
                          onChange={e => {
                            updateValues(index, 'mora_type', e.value);
                          }}
                          value={item.mora_type}
                        />
                      </div>
                    </div>
                    {/* Fim Tipo de Mora */}

                    {/* Inicio Valor da Mora */}
                    <div className="col-md-2 col-lg-2 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="mora_value">Valor da Mora:</label>
                        <input
                          disabled={item.mora_type.value === 'free'}
                          type="number"
                          className="form-control h-auto py-3 px-6"
                          name="mora_value"
                          value={item.mora_value}
                          onChange={e => {
                            updateValues(index, 'mora_value', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* Fim Valor da Mora */}

                    {/* Inicio Data de Cobrança Mora */}
                    <div className="col-md-3 col-lg-3 col-xs-12">
                      <div className="form-group fv-plugins-icon-container">
                        <label htmlFor="value">Data de Cobrança Mora:</label>
                        <input
                          disabled={item.mora_type.value === 'free'}
                          type="date"
                          className="form-control h-auto py-3 px-6"
                          name="mora_start_date"
                          value={item.mora_start_date}
                          onChange={e => {
                            updateValues(
                              index,
                              'mora_start_date',
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* Fim Data de Cobrança Mora */}
                  </div>
                </div>
              );
            })}

          {ready === true && itens.length !== 0 && (
            <div className="row">
              <div className="col-md-12 col-md-offset-2">
                <ButtonCustom
                  onClick={updateBatch}
                  layoutProps={props.layoutProps}
                >
                  Salvar Lote
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </ButtonCustom>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
export default injectIntl(
  connect(({ auth }) => ({
    user: auth.user,
  }))(LoteForm)
);
