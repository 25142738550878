import { put, takeLatest } from "redux-saga/effects";
import { initialState, types, actions } from "./actions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';

export const reducer = persistReducer(
    { 
        storage, 
        key: "register-bank", 
        whitelist: ["banks", "listBanks"] 
    },
    (state = initialState, action) => {
    switch (action.type) {

        case types.BankLoaded: {
            const { register } = action.payload;
            return { ...state, listBanks: register };
        }

        case types.BankRegisterLoaded: {
            const { register } = action.payload;
            return { ...state, banks: register };
        }

        default:
            return state;
    }
})

export function* saga() {
    yield takeLatest(types.BankRegister, function* registerBank(action) {
        const { register } = action.payload;
        yield put(actions.loadRegisterBank(register));
    });
}