import React, { useMemo } from 'react';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export function Footer() {
  const { settings } = useSelector(state => state.settings);
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">
            {today.toString()} &copy; Powered by {settings.header.title}. All
            Rights Reserved.
          </span>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <Link to="/" className="nav-link pr-3 pl-0">
            Início
          </Link>
          <a
            href={settings.footer.help}
            rel="noopener noreferrer"
            target="_blank"
            className="nav-link px-3"
          >
            Precisa de ajuda?
          </a>
          <small>v. 1.4.7</small>
        </div>
      </div>
    </div>
  );
}
