import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as actions from "../../_redux/external/actions";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from "react-intl";
import { cpfMask, cnpjMask } from "../../../../utils/mask_cpf_cnpj.utils";

import {
    Card,
    CardBody
} from '../../../../../partials/controls';
import { CardMessage } from '../../../../components/message/CardMessage';
import Select from 'react-select';
import ErrorFields from '../../../../components/alert/ErrorFields';
import { moneyMask, documentMask, removeCurrencyMask, removeMask, onlyNumbers, accountMask, formatPrice } from '../../../../utils/currency.utils';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';

import { cpfValid, cnpjValid } from '../../../../utils/valid.utils'

import DatePicker from 'react-datepicker';
import PaymentServices from '../../../../../services/PaymentServices';
import UserServices from '../../../../../services/UserServices';

function ExternalForm(props) {
    const { user, intl, findBanks, generateTransfer, banks, error_fields, fetchError } = props;
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [arrayFavoreds, setArrayFavoreds] = useState([]);
    const [allFavoreds, setAllFavoreds] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [executou, setExecutou] = useState(false);

    const [accountData, setAccountData] = useState([]);
    const [accountInfo, setAccountInfo] = useState([]);
    const [nominalAccount, setNominalAccount] = useState(false);
    const [nominalInfo, setNominalInfo] = useState([]);
    const [haveNominal, setHaveNominal] = useState(false);
    const [contaCorrente, setContaCorrente] = useState(false);
    const [savings, setSavings] = useState(false);

    // Schema de validação dos campos
    const ExternaTransferSchema = Yup.object().shape({
        bank_code: Yup.number()
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        value: Yup.string()
        .required( 
            intl.formatMessage({
                id: "BILL.REQUIRED_FIELD.VALUE",
            })
        ),
        favored: Yup.string()
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        document: Yup.string()
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ).test('Document Test', 'O número do documento esta inválido', value => {
            if (value) {
                const numbers = value.replace(/\D/g, '');
                if (numbers.length <= 11) {
                    return cpfValid(value)
                } else {
                    return cnpjValid(value)
                }
            }
        }),
        bank_branch: Yup.string()
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        account_number: Yup.string()
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        account_digit: Yup.string()
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        )
    });
    // - - -

    // Redux state
    useEffect(() => {
        findBanks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [findBanks]);

    useEffect(() => {
        if(checked){
            formik.setFieldValue("favored", user.name);
            formik.setFieldValue("document", user.person.cpf ? cpfMask(user.person.cpf) : cnpjMask(user.person.cnpj));
        }else{
            formik.setFieldValue("favored", '');
            formik.setFieldValue("document", '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    useEffect(() => {
        if (accountInfo.length > 0) {
            if (nominalAccount) {
                const nominal = accountInfo.filter(
                    (provider) => provider.type === 'nominal'
                );

                if(nominal) setNominalInfo(nominal);
            } else {
                const graphic = accountInfo.filter(
                    (provider) => provider.type === 'graphic'
                );
                if (graphic) setNominalInfo(graphic);
            }
        }
    }, [accountInfo, nominalAccount]);

    const loadInfo = async () => {
        let infoAccount = await UserServices.getAccount();
        setAccountData(infoAccount.data);

        let info = await UserServices.getAccountProvider();
        setAccountInfo(info.data.results);

        if (info.data.results.length > 0) {
            const arrayNominal = info.data.results.filter(
                (provider) => provider.type === 'graphic'
            );
            const arrayCorrente = info.data.results.filter(
                (provider) => provider.type === 'nominal'
            );

            if (arrayNominal.length > 0) {
                setNominalInfo(arrayNominal);
                setHaveNominal(true);
            }
            if (arrayCorrente.length > 0) {
                setContaCorrente(true);

                if (arrayNominal.length === 0) {
                    setNominalInfo(arrayCorrente);
                    setNominalAccount(true);
                }
            }
        }
    };

    const updateTax = () => {
        if (accountData.length === 0) return;
        let tax = 0;

        if (
            accountData.limits.bank_transfer.fee_amount > 0 ||
            accountData.limits.bank_transfer.fee_amount_nominal > 0
        ) {
            tax = nominalAccount
            ? accountData.limits.bank_transfer.fee_amount_nominal
            : accountData.limits.bank_transfer.fee_amount;
        }

        return `Taxa da Transferência: ${formatPrice(tax)}`;
    };

    const updateTaxPercent = () => {
        if (accountData.length === 0) return;
        let tax = 0;

        if (
            accountData.limits.bank_transfer.fee_percent > 0 ||
            accountData.limits.bank_transfer.fee_percent_nominal > 0
        ) {
            tax = nominalAccount
            ? accountData.limits.bank_transfer.fee_percent_nominal
            : accountData.limits.bank_transfer.fee_percent;

            tax = (tax / 100) * removeCurrencyMask(formik.values.value);
        }

        return `Taxa da Operação: ${formatPrice(tax)}`;
    };

    const loadFavoreds = async () => {
        let info = await PaymentServices.getFavoreds();
        setExecutou(true);

        if (info.data.results.length > 0) {
            setAllFavoreds(info.data.results);

            let fav = [];

            info.data.results.forEach((data, index) => {
                fav.push({
                    label: data.favored,
                    value: index
                })
            });
            
            setArrayFavoreds(fav);
        }
    };

    const fillValues = (e) => {
        let user = {};

        if (e === null) {
            // limpar campos
            formik.setFieldValue("favored", '');
            formik.setFieldValue("document", '');
            formik.setFieldValue("bank_branch", '');
            formik.setFieldValue("account_number", '');
            formik.setFieldValue("account_digit", '');
            formik.setFieldValue("bank_code", '');
            setSavings(false);       
            

            setSelectedBank(null);
        } else {
            // escolher favorecido
            user = allFavoreds[e.value];

            formik.setFieldValue("favored", user.favored);
            formik.setFieldValue("document", user.document.length === 11 ? cpfMask(user.document) : cnpjMask(user.document));
            formik.setFieldValue("bank_branch", user.bank_branch);
            formik.setFieldValue("account_number", user.account_number);
            formik.setFieldValue("account_digit", user.account_digit);
            formik.setFieldValue("bank_code", user.bank_code);
            setSavings(user.account_type === "savings" ? true : false);

            let bankInfo = banks.filter((bank) => bank.value === user.bank_code);
            setSelectedBank(bankInfo[0]);
        }
    };

    // executado assim que carrega a página
    useEffect(() => {
        if (executou === false) {
            loadFavoreds();
            loadInfo();
        }
    }, [executou]);

    const enableLoading = () => {
        setLoading(true);
    };

    // Inicializando e validando formulário
    const formik = useFormik({
        initialValues: {
            favored: '',
            document: '',
            account_number: '',
            account_digit: '',
            account_type: "checking",
            bank_branch: '',
            bank_branch_digit: '',
            bank_code: '',
            value: 'R$ 0,00',
            scheduled_date: new Date(),
            save_favored: false,
            is_nominal: false,
        },
        validationSchema: ExternaTransferSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            const data = {
                ...values,
                value: removeCurrencyMask(values.value),
                document: removeMask(values.document),
                save_favored: favorite,
                is_nominal: nominalAccount,
                account_type: savings ? 'savings' : 'checking',
            }            

            if (parseFloat(data.value) <= 0) {
                alert('Valor precisa ser maior que 0!');
                return false;
            }

            delete data.scheduled_date;

            const now = (new Date()).toISOString().substr(0, 10);
            const scheduledDate = values.scheduled_date.toISOString().substr(0, 10);
            if (now !== scheduledDate) data.scheduled_date = scheduledDate;

            enableLoading(); 
            setTimeout(() => {
                try {
                    generateTransfer(data)
                    enableLoading(); 
                    setLoading(false)
                } catch (error) {
                    console.log(error)
                    setSubmitting(false);
                    setLoading(false)
                }
            }, 1000)
        }
    });
    // - - - 

    return (<>
        {/** begin::ModalError */}
        <ErrorFields
            error_fields={error_fields}
            show={(error_fields !== '') ? (true) : (false)}
            onHide={() => {
                fetchError('')
                setLoading(false)
            }}
        />
        {/** end::ModalError */}
        <CardMessage 
            message="Preencha corretamente os campos abaixo para realizar uma transferência (TED)." 
        >
            <div>
                <br />
                {updateTax()}
                <br />  
                {updateTaxPercent()}
            </div>
        </CardMessage>

        <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardBody>
                <form 
                    onSubmit={formik.handleSubmit}>

                    <div className="row"> 
                        {/** begin::Banco*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="value">Selecione o banco de destino:</label>
                                <Select
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                        ...theme.colors,
                                        primary: 'none',
                                        },
                                    })}
                                    name="bank_code"
                                    placeholder="-- Selecione --"
                                    options={banks}
                                    onChange={(e) => {
                                        formik.setFieldValue("bank_code", e.value);

                                        let bankInfo = banks.filter((bank) => bank.value === e.value);
                                        setSelectedBank(bankInfo[0]);
                                    }}
                                    value={selectedBank}
                                />       
                                {formik.touched.bank_code && formik.errors.bank_code ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.bank_code}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Banco*/}

                        {/** begin::Valor*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="value">Valor:</label>
                                <input
                                    placeholder="R$ 0,00"
                                    type="text"
                                    className="form-control h-auto py-3 px-6"
                                    name="value"
                                    {...formik.getFieldProps("value")}
                                    onChange={(e) => {
                                        formik.setFieldValue("value", moneyMask(e.target.value));
                                    }}
                                />       
                                {formik.touched.value && formik.errors.value ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.value}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Valor*/}

                        {/** begin::MY_ACCOUNT*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="row mx-sm-1 mx-md-1 mx-lg-1 mx-xs-1 my-sm-4 my-md-4 my-lg-4 my-xs-4">
                                <input
                                    type="checkbox"
                                    onChange={() => { setChecked(!checked)}}
                                    name="myAccount"
                                />
                                <div className="mx-md-2">
                                <label htmlFor="myAccount">
                                    A conta a ser creditada é minha
                                </label>
                                </div>
                            </div>
                            <div className="row mx-sm-1 mx-md-1 mx-lg-1 mx-xs-1 my-sm-4 my-md-4 my-lg-4 my-xs-4">
                                <input
                                    type="checkbox"
                                    onChange={() => { setFavorite(!favorite)}}
                                    name="save_favored"
                                />
                                <div className="mx-md-2">
                                <label htmlFor="save_favored">
                                    Salvar como favorito
                                </label>
                                </div>
                            </div>
                            <div className="row mx-sm-1 mx-md-1 mx-lg-1 mx-xs-1 my-sm-4 my-md-4 my-lg-4 my-xs-4">
                                <input
                                    type="checkbox"
                                    onChange={() => { setSavings(!savings)}}
                                    name="savings"
                                    checked={savings}
                                />
                                <div className="mx-md-2">
                                <label htmlFor="savings">
                                    Conta poupança
                                </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="value">Selecione um favorito:</label>
                                <Select
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                        ...theme.colors,
                                        primary: 'none',
                                        },
                                    })}
                                    name="star"
                                    placeholder="-- Selecione --"
                                    isClearable={true}
                                    options={arrayFavoreds}
                                    onChange={(e) => {
                                        fillValues(e);
                                    }}
                                />
                            </div>
                        </div>
                        {/** end::MY_ACCOUNT*/}

                        {/** begin::Favorecido*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="favored">Favorecido:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-3 px-6"
                                    name="favored"
                                    {...formik.getFieldProps("favored")}
                                    onChange={(e) => {
                                        formik.setFieldValue("favored", e.target.value);
                                    }}
                                />            
                                {formik.touched.favored && formik.errors.favored ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.favored}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Favorecido*/}

                        {/** begin::CPF_ou_CNPJ*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="document">CPF ou CNPJ:</label>
                                <input
                                    maxLength='18'
                                    type="text"
                                    className="form-control h-auto py-3 px-6"
                                    name="document"
                                    {...formik.getFieldProps("document")}
                                    onChange={(e) => {
                                        formik.setFieldValue("document", documentMask(e.target.value));
                                    }}
                                />       
                                {formik.touched.document && formik.errors.document ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.document}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::CPF_ou_CNPJ*/}

                        {/** begin::Agencia*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="bank_branch">Agência:</label>
                                <input
                                    placeholder="sem dígito*"
                                    type="text"
                                    className="form-control h-auto py-3 px-6"
                                    name="bank_branch"
                                    {...formik.getFieldProps("bank_branch")}
                                    onChange={(e) => {
                                        formik.setFieldValue("bank_branch", onlyNumbers(e.target.value));
                                    }}
                                />       
                                {formik.touched.bank_branch && formik.errors.bank_branch ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.bank_branch}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Agencia*/}

                        {/** begin::Conta*/}
                        <div className="col-md-4 col-lg-4 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="account_number">Conta:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-3 px-6"
                                    name="account_number"
                                    {...formik.getFieldProps("account_number")}
                                    onChange={(e) => {
                                        formik.setFieldValue("account_number", onlyNumbers(e.target.value, 15));
                                    }}
                                />       
                                {formik.touched.account_number && formik.errors.account_number ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.account_number}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Conta*/}

                        {/** begin::Digito*/}
                        <div className="col-md-2 col-lg-2 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="account_digit">Dígito:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-3 px-6"
                                    name="account_digit"
                                    {...formik.getFieldProps("account_digit")}
                                    onChange={(e) => {
                                        formik.setFieldValue("account_digit", onlyNumbers(e.target.value, 1));
                                    }}
                                />       
                                {formik.touched.account_digit && formik.errors.account_digit ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.account_digit}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Digito*/}

                        {/** begin::ScheduledDate*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="scheduled_date">Data:</label>
                                <DatePicker
                                    minDate={new Date()}
                                    className="form-control h-auto py-4 px-6"
                                    style={{ width: '100%' }}
                                    placeholderText="dd/mm/yyyy"
                                    selected={formik.values.scheduled_date}
                                    onChange={val => {
                                        formik.setFieldValue('scheduled_date', val);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        {/** end::ScheduledDate*/}
                        
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="row mx-sm-1 mx-md-1 mx-lg-1 mx-xs-1 my-sm-4 my-md-4 my-lg-4 my-xs-4">
                                {(haveNominal || contaCorrente) && (
                                    <>
                                        <p>
                                            De qual conta deseja enviar?
                                        </p> &nbsp;
                                        <div>
                                            {haveNominal && (
                                                <>
                                                    <input
                                                        type="radio"
                                                        onChange={() => { setNominalAccount(false)}}
                                                        name="nominal"
                                                        checked={!nominalAccount}
                                                    />&nbsp;Conta Digital
                                                    &nbsp;&nbsp;&nbsp;
                                                </>
                                            )}
                                            {contaCorrente && (
                                                <>
                                                    <input
                                                        type="radio"
                                                        onChange={() => { setNominalAccount(true)}}
                                                        name="nominal"
                                                        checked={nominalAccount}
                                                    />&nbsp;Conta Corrente
                                                </>
                                            )}
                                        </div>
                                        <br />
                                        {nominalInfo.length > 0 && (
                                            <div className="boxNominalInfo">
                                                <div className="rowNominalInfo">
                                                    <span className="titleNominalInfo">Nome:</span>&nbsp;&nbsp;
                                                    <span>{nominalInfo[0].receiver_name}</span>
                                                </div>
                                                <div className="rowNominalInfo">
                                                    <span className="titleNominalInfo">Agência sem dígito:</span>&nbsp;&nbsp;
                                                    <span>{nominalInfo[0].branch.slice(0, 4)}</span>
                                                </div>
                                                <div className="rowNominalInfo">
                                                    <span className="titleNominalInfo">Conta com dígito:</span>&nbsp;&nbsp;
                                                    <span>{accountMask(nominalInfo[0].number)}</span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-md-offset-2">
                            <ButtonCustom
                                layoutProps={props.layoutProps}
                                disabled={loading}
                            >
                                Realizar transferência
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </ButtonCustom>
                        </div>
                    </div>
                   
                </form>
            </CardBody>
        </Card>
    </>);
}
export default injectIntl(connect(
    ({ external, auth }) => ({ 
        banks: external.banks, 
        message: external.message,
        error_fields: external.error_fields,
        user: auth.user,
    }),
   actions.actions)(ExternalForm));

