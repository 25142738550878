import { ENDPOINT } from "../../../../../services/enums/EndPoint";
import ApiServices from "../../../../../services/ApiServices";

const api = ApiServices;

/**
 * Cria uma conta de pessoa física / jurídica
 */
export const registerPF = ({ 
    basic, 
    financial, 
    password, 
    personal, 
    phone, 
    zipcode,
    banks
}) => {
    return api.post(ENDPOINT.REGISTER_PERSON, {
        email: basic.email,
        password: password.password,
        phone_id: phone.id,
        zip_code: zipcode.zipCode,
        city: zipcode.city,
        state: zipcode.state,
        country: 'Brasil',
        street_name: zipcode.street,
        number: `${zipcode.number}`,
        district: zipcode.district,
        complement: zipcode.additional_notes,
        name: basic.name,
        cpf: basic.cpf,
        gender: personal.gender,
        document: personal.document,
        document_shipping_agency: personal.document_shipping_agency,
        document_shipping_date: personal.document_shipping_date,
        mother_name: personal.mother_name,
        birth: personal.birth,
        politically_exposed_person: financial.politically_exposed_person,
        occupation: financial.occupation,
        declared_equity: financial.declared_equity,
        monthly_income: financial.monthly_income,
        banks: banks,
    });
}


export const registerPJ = ({ 
    basic, 
    financial, 
    password, 
    personal, 
    phone, 
    zipcode,
    banks
}) => {
    return api.post(ENDPOINT.REGISTER_PERSON, {
        name: basic.name,
        trade_name: basic.trade_name,
        cnpj: basic.cnpj,
        email: basic.email,
        password: password.password,
        phone_id: phone.id,
        zip_code: zipcode.zipCode,
        city: zipcode.city,
        state: zipcode.state,
        country: 'Brasil',
        street_name: zipcode.street,
        number: `${zipcode.number}`,
        district: zipcode.district,
        complement: zipcode.additional_notes,
        declared_billing: financial.declared_billing,
        declared_equity: financial.company_declared_equity,
        accountable: {
            cpf: personal.cpf,
            gender: personal.gender,
            name: personal.name,
            document: personal.document,
            document_shipping_agency: personal.document_shipping_agency,
            document_shipping_date: personal.document_shipping_date,
            mother_name: personal.mother_name,
            birth: personal.birth,
            politically_exposed_person: financial.politically_exposed_person,
            occupation: financial.occupation,
            declared_equity: financial.declared_equity,
            monthly_income: financial.monthly_income,
        },
        banks: banks,
    });
}

export const sendDocuments = (form) => {
    return api.post(ENDPOINT.REGISTER_DOCUMENTS, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      })
}

export const updateDocuments = (form) => {
    return api.post(ENDPOINT.UPDATE_DOCUMENTS, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      })
}