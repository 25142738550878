import React from "react";
import {
  Card,
  CardBody
} from "../../../../partials/controls";
import DepositTable from "./table/DepositTable";

export function DepositCard() {
  return (
    <Card className="col-xs-12 col-xl-12 col-md-12">
      <CardBody>
        <h4>Últimos depósitos</h4>
        <p className="text-muted mt-1">
          Confira as últimas solicitações de depósito em sua conta
        </p>
        <DepositTable />
      </CardBody>
    </Card>
  );
}
