import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as fromState from './_redux/actions';

import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { ButtonSelector } from '../../../../components/button/ButtonSelector';
import { useHtmlClassService } from '../../../../components';

const initialValues = {
  document: '',
  document_shipping_agency: '',
  document_shipping_date: '',
  gender: null,
  mother_name: '',
  birth: '',
};

function PersonalInformation(props) {
  const { registerPersonalInformation } = props;
  const history = useHistory();
  const intl = useIntl();
  const [gender, setGender] = useState(true);
  props.setPercent(30);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const RegistrationSchema = Yup.object().shape({
    document: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    document_shipping_agency: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    document_shipping_date: Yup.date().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    mother_name: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    birth: Yup.date().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const nextStep = () => {
    history.push('/cadastro/conta-fisica/financeiro');
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      values.gender = gender ? 'male' : 'female';
      registerPersonalInformation(values);
      nextStep();
    },
  });

  const nameString = text => `${text}`.replace(/\d/g, '');

  return (
    <>
      <div className="login-form login-signin" style={{ display: 'block' }}>
        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {/* begin: Alert */}
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* end: Alert */}

          {/* begin: Sexo */}
          <div className="form-group fv-plugins-icon-container">
            <label>
              <FormattedMessage id="REGISTER.LABEL.GENDER" />
            </label>

            <div className="row ml-2">
              <ButtonSelector
                layoutProps={layoutProps}
                type="button"
                onClick={() => {
                  setGender(true);
                }}
                hover={gender}
                className="btn mr-5 font-weight-bold px-6 py-2 my-2"
              >
                <FormattedMessage id="REGISTER.LABEL.MALE_GENDER" />
              </ButtonSelector>

              <ButtonSelector
                layoutProps={layoutProps}
                type="button"
                onClick={() => {
                  setGender(false);
                }}
                hover={gender ? false : true}
                className="btn font-weight-bold px-6 py-2 my-2"
              >
                <FormattedMessage id="REGISTER.LABEL.FEMALE_GENDER" />
              </ButtonSelector>
            </div>
          </div>
          {/* end: Sexo */}

          {/* begin: Document */}
          <div className="form-group fv-plugins-icon-container">
            <label className="text-muted">
              <FormattedMessage id="REGISTER.PHYSICAL.IDENDIFICATION" />
            </label>
            <input
              type="text"
              className={`form-control h-auto py-5 px-6 ${getInputClasses(
                'document'
              )}`}
              name="document"
              {...formik.getFieldProps('document')}
            />
            {formik.touched.document && formik.errors.document ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.document}</div>
              </div>
            ) : null}
          </div>
          {/* end: Document */}

          {/* begin: Emissão */}
          <div className="form-group fv-plugins-icon-container">
            <label className="text-muted">
              <FormattedMessage id="REGISTER.LABEL.SHIPPING_DATE" />
            </label>
            <input
              type="date"
              className={`form-control h-auto py-5 px-6 ${getInputClasses(
                'document_shipping_date'
              )}`}
              name="document_shipping_date"
              {...formik.getFieldProps('document_shipping_date')}
            />
            {formik.touched.document_shipping_date &&
            formik.errors.document_shipping_date ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.document_shipping_date}
                </div>
              </div>
            ) : null}
          </div>
          {/* end: Emissão */}

          {/* begin: Username */}
          <div className="form-group fv-plugins-icon-container">
            <label className="text-muted">
              <FormattedMessage id="REGISTER.LABEL.SHIPPING_AGENCY" />
            </label>
            <input
              type="text"
              className={`form-control h-auto py-5 px-6 ${getInputClasses(
                'document_shipping_agency'
              )}`}
              name="document_shipping_agency"
              {...formik.getFieldProps('document_shipping_agency')}
            />
            {formik.touched.document_shipping_agency &&
            formik.errors.document_shipping_agency ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.document_shipping_agency}
                </div>
              </div>
            ) : null}
          </div>
          {/* end: Username */}

          {/* begin: Username */}
          <div className="form-group fv-plugins-icon-container">
            <label className="text-muted">
              <FormattedMessage id="REGISTER.LABEL.BIRTH" />
            </label>
            <input
              type="date"
              className={`form-control h-auto py-5 px-6 ${getInputClasses(
                'birth'
              )}`}
              name="birth"
              {...formik.getFieldProps('birth')}
            />
            {formik.touched.birth && formik.errors.birth ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.birth}</div>
              </div>
            ) : null}
          </div>
          {/* end: Username */}

          {/* begin: Mother */}
          <div className="form-group fv-plugins-icon-container">
            <label className="text-muted">
              <FormattedMessage id="REGISTER.LABEL.MOTHER_NAME" />
            </label>
            <input
              type="text"
              className={`form-control h-auto py-5 px-6 ${getInputClasses(
                'mother_name'
              )}`}
              name="mother_name"
              {...formik.getFieldProps('mother_name')}
              onChange={e => {
                formik.setFieldValue('mother_name', nameString(e.target.value));
              }}
            />
            {formik.touched.mother_name && formik.errors.mother_name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.mother_name}</div>
              </div>
            ) : null}
          </div>
          {/* end: Mother */}

          <div className="form-group d-flex flex-wrap flex-center">
            <div className="col-md-10">
              {/** "/cadastro/conta-fisica/financeiro" */}
              <ButtonCustom
                layoutProps={layoutProps}
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
                size="sm"
              >
                <FormattedMessage id="AUTH.REGISTER.NEXT" />
              </ButtonCustom>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default connect(
  ({ physicalPerson }) => ({}),
  fromState.actions
)(PersonalInformation);
