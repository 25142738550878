import { phoneRechargeSlice, callTypes } from "./PhoneRechargeSlice";
import { HTTPCodes } from '../../../../../services/enums/HTTPCodes';
import PhoneServices from '../../../../../services/PhoneServices';

const { actions } = phoneRechargeSlice;

export const fetchPhoneRecharge = page => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return PhoneServices
        .getAllRecharges(page)
        .then(response => {
            const { meta: { pagination }, results } = response.data;

            dispatch(actions.phoneRechargeFetched({ pagination, entities: results }));
        })
        .catch(error => {
            error.clientMessage = "Can't find customers";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const makeRecharge = params => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return PhoneServices
        .generateRecharge(params)
        .then(res => {
            const { operation } = res.data;
            dispatch(actions.fetchTransfer(operation));
            fetchPhoneRecharge(1)
        })
        .catch(err => {
            if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
				const { message } = "Serviço indisponível";
                dispatch(actions.fetchError([{ message }]));
            } else {
                const { data: { field_errors, message } } = err.response;
                dispatch(actions.fetchError((field_errors.length > 0 ) ?
                (Object.values(field_errors)[0]) : 
                ([{message}])));
            }
        });
};

export const findCarriers = params => dispatch => {
    dispatch(actions.startCarriers({ callType: callTypes.list }));
    return PhoneServices
        .getCarriersByAreaCode(params)
        .then(response => {
            const { results } = response.data;

            dispatch(actions.carriersFetched({ results }));
        })
        .catch(error => {
            error.clientMessage = "Can't find";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const findPlanValues = operation => dispatch => {
    dispatch(actions.startPlans({ callType: callTypes.list }));
    return PhoneServices
        .getValuesByCarriers(operation)
        .then(response => {
            const data = response.data;

            dispatch(actions.plansFetched(data));
        })
        .catch(error => {
            error.clientMessage = "Can't find";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchError = params => dispatch => {
    dispatch(actions.fetchError(params));
}
