import React from 'react';
import { BillUIProvider } from "./BillUIContext";

import BillForm from './form/BillForm';
import SecurityTransaction from '../../SecurityTransaction';
import { useSelector } from 'react-redux';

export function BillPage ({ history }){
    const { operation } = useSelector(state => state.bill);

    const billUIEvents = {
        openFetchBillDialog: () => {
            history.push(`/pagamento/boleto`);
        }
    }

    return (
        <BillUIProvider billUIEvents={billUIEvents}>
            <div className="col-md-8 col-xs-12 col-lg-8">
                {(() => {
                    if(operation.length !== 0) {
                        return <SecurityTransaction 
                                    operation={operation} 
                                />
                    }
                    return (
                        <div>
                            <BillForm />
                        </div>)
                })()}
                {/* <BillCard /> */}
            </div>
        </BillUIProvider>
    )
} 
