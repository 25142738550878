import React from 'react';
import { useSelector } from "react-redux";

import QuickShortcuts from '../quick-shortcuts/QuickShortcuts';
import Balance from '../balance/Balance';

export function Subaside() {
    const { financial } = useSelector(
		({ auth }) => ({ financial: auth.financial })
    )
    
    return(
        <div className="col-md-4 col-xs-12 col-lg-4">
            <Balance />
            {(() => {
                if (financial.status === 'approved') {
                    return <QuickShortcuts />;
                }
            })()}
        </div>
    )
}