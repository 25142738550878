export const initialState = {
    listBanks: [],
    banks: []
}

export const types = {
    BankRegister: "[Register] Bank",
    BankRegisterLoaded: "[Loaded] Bank",
    BankLoaded: "[Loaded] Bank Register"
}

export const actions = {
    registerBank: register => ({ 
        type: types.BankRegister, 
        payload: { register } 
    }),
    loadRegisterBank: register => ({ 
        type: types.BankRegisterLoaded, 
        payload: { register } 
    }),
    loadBank: register => ({ 
        type: types.BankLoaded, 
        payload: { register } 
    })
}