import { ENDPOINT } from '../../../../../../../services/enums/EndPoint';
import ApiServices from '../../../../../../../services/ApiServices';

const api = ApiServices;

/**
 * Cadastra telefone
 */
export const createPhone = data => {
  return api.post(ENDPOINT.PHONE_REGISTER, data);
};

/**
 * Verifica telefone cadastrado
 */
export const verifyPhone = data => {
  return api.put(
    `${ENDPOINT.PHONE_REGISTER}/${data.id}${ENDPOINT.PHONE_VERIFICATION}`,
    { code: data.code }
  );
};

/**
 * Reenvia código ao telefone cadastrado
 */
export const resendCode = data => {
  return api.put(
    `${ENDPOINT.PHONE_REGISTER}/${data.id}${ENDPOINT.PHONE_RESEND_CODE}`,
    { code: data.code }
  );
};
