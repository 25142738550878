import { HTTPCodes } from '../../../../../services/enums/HTTPCodes';
import PaymentServices from '../../../../../services/PaymentServices';
import BankServices from '../../../../../services/BankServices';
import { put, takeLatest } from "redux-saga/effects";
import { initialExternalState, types, actions } from "./actions";


export const reducer = (state = initialExternalState, action) => {
    switch (action.type) {

        case types.ExternalTransferLoaded: {
            const { results, meta } = action.payload.external;
            return { ...state, results, meta, operation: [] };
        }

        case types.BanksLoaded: {
            return { ...state, banks: action.payload.operation};
        }

        case types.ExternalOperationLoaded: {
            return { ...state, operation: action.payload};
        }

        case types.ErrorLoaded: {
            return { ...state, error_fields: action.payload.error, listLoading: false};
        }

        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(types.ExternalTransferRequest, function* requestExternalTransfer(action) {
        try {
            const { data } = yield PaymentServices.getAllExternalTransfers(action.payload.external)
            yield put(actions.fetchExternalTransfer(data));
        } catch (err) {
            const { data: { message } } = err.response;
            yield put(actions.fetchError([message]));
        }
    });

    yield takeLatest(types.BanksRequest, function* bankRequest() {
        try {
            let { data: results } = yield BankServices.getAllBanks()
            yield results = results.results.map((v, i) => {
                return {
                    value: v.code,
                    label: `(${v.code}) ${v.name}`
                }
            });

            yield put(actions.fetchBanks(results));
        } catch (err) {
            const { data: { message } } = err.response;
            yield put(actions.fetchError([message]));
        }
    });

    yield takeLatest(types.ExternalOperationRequest, function* createTransfer(action) {
        try {
            const { data: { operation } } = yield PaymentServices.generateExternalTransfer(action.payload)
            yield put(actions.fetchTransfer(operation));
        } catch (err) {
            let message_error = '';

            switch (err.response.status) {
                case HTTPCodes.UNKNOWN_SERVER_ERROR: 
                    message_error = `[${err.response.status}] Serviço indisponível`;
                    yield put(actions.fetchError([{ message: message_error }]));
                break;

                case HTTPCodes.FORBIDDEN:
                    const { data: { message } } = err.response;
                    message_error = `[${err.response.status}] ${message}`;
                    yield put(actions.fetchError([{ message: message_error }]));

                break;
            
                default:
                    const { data: { field_errors } } = err.response;
                    yield put(actions.fetchError(Object.values(field_errors)[0]));
                break;
            }
        }
    });
}