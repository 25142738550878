import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import {
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../../helpers";
import * as uiHelpers from "../FavoritesUIHelpers";
import * as columnFormatters from "./formatters";
import { Pagination } from "../../../../../partials/controls";
import { NotFound } from "../../../../components/message/NotFound";
import PaymentServices from '../../../../../services/PaymentServices';

function FavoritesTable(props) {
  const [meta, setMeta] = useState([]);
  const [results, setResults] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const loadFavoreds = async () => {
    setListLoading(true);

    let info = await PaymentServices.getFavoreds();

    setListLoading(false);
    if (info.data.results.length > 0) {
        let fav = [];

        info.data.results.forEach((data, index) => {
            fav.push({
                id: data.id,
                document: data.document,
                name: data.favored,
                value: index
            })
        });
        
        setResults(fav);
        setMeta(info.data.meta);
    }
  };

  useEffect(() => {
    loadFavoreds();
  },[]);

  const columns = [
    {
      text: 'Nome',
      align: 'center',
      headerAlign: 'center',
      dataField: "name",
      sort: true,
    },
    {
      text: 'CPF/CNPJ',
      align: 'center',
      headerAlign: 'center',
      dataField: "document",
      sort: false,
      formatter: columnFormatters.DocumentColumnFormatter
    },
    {
      text: 'AÇÕES',
      align: 'center',
      headerAlign: 'center',
      dataField: "id",
      sort: false,
      formatter: columnFormatters.ButtonColumnFormatter
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: meta.pagination?.total_count || 1,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: meta.pagination?.per_page || 1,
    paginationSize: meta.pagination?.total_pages || 1,
    page: meta.pagination?.current_page || 1
  };
  return (
    (() => {
      if (results.length > 0) {
        return (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    remote
                    keyField="id"
                    data={results === null ? [] : results}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    onTableChange={() => {}}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={results} />
                    <NoRecordsFoundMessage entities={results} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )
      } else {
        return <NotFound message="Nenhum dado encontrado no momento." />;
      }
    })()
  );
}

export default FavoritesTable;
