import React, { useState, useMemo } from 'react';

import { Card, CardHeader, CardBody } from '../../../partials/controls';
import { injectIntl } from 'react-intl';
import './QuickShortcuts.css';
import { moneyMask } from '../../utils/currency.utils';

import { ButtonCustom } from '../button/ButtonCustom';

import { useHtmlClassService } from '../../_core/MetronicLayout';

import { billetBatchStore } from '../../../store/Batch';

import { ENDPOINT } from '../../../services/enums/EndPoint';

import Axios from 'axios';
import * as XLSX from 'xlsx';

function QuickShortcuts() {
  const { billets, currentBillet } = billetBatchStore(store => store.state);
  const { updateBillets, updateCurrentBillet } = billetBatchStore(
    store => store.actions
  );
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const generateBatch = async () => {
    if (loading) return false;

    let novoArray = [];
    if (billets.length > 0) {
      for (let pos in billets) {
        for (let posInterno in billets[pos]) {
          if (billets[pos][posInterno]?.payer?.name === '') {
            alert('Lotes inválidos!');
            return false;
          } else {
            novoArray.push({
              due_date: billets[pos][posInterno].due_date,
              fine_start_date: billets[pos][posInterno].fine_start_date,
              fine_type: billets[pos][posInterno].fine_type.value,
              fine_value: billets[pos][posInterno].fine_value,
              mora_start_date: billets[pos][posInterno].mora_start_date,
              mora_type: billets[pos][posInterno].mora_type.value,
              mora_value: billets[pos][posInterno].mora_value,
              value: billets[pos][posInterno].value,
              payer: billets[pos][posInterno].payer,
            });
          }
        }
      }
    }

    if (title === '') {
      alert('Preencha o título');
    } else {
      const obj = {
        title,
        is_nominal: false,
        items: novoArray,
      };
      setLoading(true);

      Axios.post(ENDPOINT.BOLETO_BATCH, obj)
        .then(responseSuccess => {
          setLoading(false);
          console.log(responseSuccess);

          alert('Lote gerado!');
          window.location.href = '/conta/depositolote';
        })
        .catch(responseError => {
          setLoading(false);

          alert('Falha ao gerar lote!');
          console.error(responseError);
        });
    }
  };

  const addPagador = () => {
    updateCurrentBillet(currentBillet + 1);

    let curr = [...billets];

    curr.push({
      due_date: '',
      fine_start_date: '',
      fine_type: 'free',
      fine_value: 0,
      mora_start_date: '',
      mora_type: 'free',
      mora_value: 0,
      value: '',
      payer: {
        city: '',
        complement: '',
        district: '',
        document: '',
        email: '',
        name: '',
        number: '',
        state: '',
        street_name: '',
        zipcode: '',
      },
    });
    updateBillets(curr);
  };

  const fineTypes = {
    'Sem Multa': {
      label: 'Sem Multa',
      value: 'free',
    },
    Absoluto: {
      label: 'Absoluto',
      value: 'absolut',
    },
    Percentual: {
      label: 'Percentual',
      value: 'percentage',
    },
  };

  const moraTypes = {
    'Sem Mora': {
      label: 'Sem Mora',
      value: 'free',
    },
    'Valor Diário': {
      label: 'Valor Diário',
      value: 'day_value',
    },
    'Porcentagem Mensal': {
      label: 'Porcentagem Mensal',
      value: 'monthly_percentage',
    },
    'Padrão Bancário': {
      label: 'Padrão Bancário',
      value: 'bank_default',
    },
  };

  const handleFile = async e => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      blankrows: false,
      header: 1,
      defval: '',
    });

    updateBillets([]);

    let curr = [];
    for (let j in jsonData) {
      let lote = [];
      if (jsonData[j] === undefined) return;
      if (jsonData[j][0] === 'Título') setTitle(jsonData[j][1]);
      if (jsonData[j][0] === 'Email') {
        //j+1 tem os dados do pagador.
        let number = parseInt(j);
        let _p = jsonData[number + 1];

        let payer = {
          email: _p[0],
          name: _p[1],
          document: _p[4] + '',
          street_name: _p[5],
          number: _p[6] + '',
          zipcode: _p[7] + '',
          complement: _p[8],
          city: _p[9],
          state: _p[10],
          district: _p[11],
        };
        let _valor = _p[2];
        let _parcelas = _p[3];

        for (let y = 1; y <= _parcelas; y++) {
          let parcela = jsonData[number + 2 + y];
          lote.push({
            due_date: parcela[1],
            fine_type: fineTypes[parcela[2]] || fineTypes['Sem Multa'],
            fine_value: parcela[3],
            fine_start_date: parcela[4],
            mora_type: moraTypes[parcela[5]] || moraTypes['Sem Multa'],
            mora_value: parcela[6],
            mora_start_date: parcela[7],
            value: _valor / _parcelas,
            currentValue: _valor,
            length: _parcelas,
            payer,
          });
        }

        curr.push(lote);
      }
    }

    updateBillets(curr);
    updateCurrentBillet(1);
  };

  return (
    <>
      <Card className="column col-xs-12 col-xl-12 col-md-12">
        <CardHeader title="Importar Arquivo">
          <input type="file" accept=".xlsx" onInput={e => handleFile(e)} />
        </CardHeader>
      </Card>
      <Card className="column col-xs-12 col-xl-12 col-md-12">
        {billets.length > 0 && (
          <CardHeader title="">
            <div style={{ width: '100%', marginBottom: '15px' }}>
              <input
                type="text"
                name="title"
                placeholder="Nome do Lote"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </div>
            <ButtonCustom onClick={generateBatch} layoutProps={layoutProps}>
              {!loading && 'Gerar Lote'}
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </ButtonCustom>
          </CardHeader>
        )}
        <CardBody>
          <div className="column">
            <button onClick={addPagador}>Adicionar Pagador</button>
            {currentBillet > 0 &&
              billets.length > 0 &&
              billets.map((billet, index) => {
                console.log(billet);
                return (
                  <div
                    className={`quickshortcut ${
                      currentBillet === index + 1 ? 'quickshortcutSelected' : ''
                    }`}
                    onClick={() => updateCurrentBillet(index + 1)}
                  >
                    <div className="col-md-12 col-lg-12 col-xs-12 mb-4 mt-4">
                      <h4 className="text-dark-65">Lote {index + 1}</h4>
                      <p className="text-muted mt-1">
                        {billet[0]?.payer?.name || '-'} <br />
                        {billet[0]?.value
                          ? moneyMask(billet[0]?.currentValue * 100)
                          : '-'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default injectIntl(QuickShortcuts);
