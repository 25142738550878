import React, { useState, useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import * as actions from "../../_redux/phone-recharge/PhoneRechargeActions";
import ErrorFields from '../../../../components/alert/ErrorFields';

import {
    Card,
    CardBody
} from '../../../../../partials/controls';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { injectIntl } from "react-intl";
import { CardMessage } from '../../../../components/message/CardMessage';
import { currency, phoneMaskWithoutDDD, removeMask, formatPrice } from '../../../../utils/currency.utils';
import { useHtmlClassService } from '../../../../_core/MetronicLayout';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';

import UserServices from '../../../../../services/UserServices';

function PhoneRechargeForm(props) {
    const { intl, makeRecharge, findCarriers, carriers, fetchError,
        loadCarriers, findPlanValues, loadPlans, plans, error_fields } = props;
    const [loading, setLoading] = useState(false);
    const [accountInfo, setAccountInfo] = useState([]);

    const uiService = useHtmlClassService();
    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            colors: uiService.getColors(),
        };
    }, [uiService]);

    // Schema de validação dos campos
    const Schema = Yup.object().shape({
        phone_ddd: Yup.number()
        .min(2, "Minimum 10 symbols")
        .required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        )
    });
    // - - -

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const loadInfo = async () => {
        let info = await UserServices.getAccount();
        setAccountInfo(info.data);
    };
    
    useEffect(() => {
        loadInfo();
    },[]);

    const updateTax = () => {
        if (accountInfo.length === 0) return;
        
        let tax = 0;

        if (accountInfo.limits.phone_recharge.fee_amount > 0) {
            tax = accountInfo.limits.phone_recharge.fee_amount;
        }

        return `Taxa de Recarga: ${formatPrice(tax)}`;
    };

    const updateTaxPercent = () => {
        if (accountInfo.length === 0) return;
        let tax = 0;

        if (accountInfo.limits.phone_recharge.fee_percent > 0) {
            tax = accountInfo.limits.phone_recharge.fee_percent;

            tax = (tax / 100) * formik.values.value;
        }

        return `Taxa de serviço: ${formatPrice(tax)}`;
    };

    // Inicializando e validando formulário
    const formik = useFormik({
        initialValues: {
            carrier_id: "",
            phone_ddd: "",
            phone_number: "",
            value: "",
        },
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {   
            const data = {
                ...values,
                phone_number: removeMask(values.phone_number)
            };
            enableLoading(); 
            setTimeout(() => {
                try {
                    makeRecharge(data)
                    disableLoading();
                } catch (error) {
                    setSubmitting(false);
                    disableLoading();
                }
            }, 1000)
        },
    });
    // - - - 

    return (<>

        {/** begin::ModalError */}
        <ErrorFields
            error_fields={error_fields}
            show={(error_fields !== '') ? (true) : (false)}
            onHide={() => {
                fetchError('')
                setLoading(false)
            }}
        />
        {/** end::ModalError */}

        <CardMessage 
            message="Selecione o DDD, digite o número do celular, selecione a operadora e o valor desejado para efetuar sua recarga." 
        >
            <div>
                <br />
                {updateTax()}
                <br />  
                {updateTaxPercent()}
            </div>
        </CardMessage>
        
        <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardBody>
                <form 
                    onSubmit={formik.handleSubmit}>

                    <div className="row"> 
                       
                        {/** begin::DDD*/}
                        <div className="col-md-3 col-lg-3 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="phone_ddd">DDD:</label>
                                <select
                                    placeholder="(DDD)"
                                    type="text"
                                    className="custom-select form-control h-auto py-4 px-6"
                                    name="phone_ddd"
                                    {...formik.getFieldProps("phone_ddd")}
                                    onChange={(e) => {
                                        findCarriers(e.target.value);
                                        formik.setFieldValue("phone_ddd", e.target.value);
                                    }}
                                >
                                    <option value=""></option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="41">41</option>
                                    <option value="42">42</option>
                                    <option value="43">43</option>
                                    <option value="44">44</option>
                                    <option value="45">45</option>
                                    <option value="46">46</option>
                                    <option value="47">47</option>
                                    <option value="48">48</option>
                                    <option value="49">49</option>
                                    <option value="51">51</option>
                                    <option value="52">52</option>
                                    <option value="53">53</option>
                                    <option value="54">54</option>
                                    <option value="55">55</option>
                                    <option value="56">56</option>
                                    <option value="57">57</option>
                                    <option value="58">58</option>
                                    <option value="59">59</option>
                                    <option value="61">61</option>
                                    <option value="62">62</option>
                                    <option value="63">63</option>
                                    <option value="64">64</option>
                                    <option value="65">65</option>
                                    <option value="66">66</option>
                                    <option value="67">67</option>
                                    <option value="68">69</option>
                                    <option value="71">71</option>
                                    <option value="72">72</option>
                                    <option value="73">73</option>
                                    <option value="74">74</option>
                                    <option value="75">75</option>
                                    <option value="76">76</option>
                                    <option value="77">77</option>
                                    <option value="78">78</option>
                                    <option value="79">79</option>
                                    <option value="81">81</option>
                                    <option value="82">82</option>
                                    <option value="83">83</option>
                                    <option value="84">84</option>
                                    <option value="85">85</option>
                                    <option value="86">86</option>
                                    <option value="87">87</option>
                                    <option value="88">88</option>
                                    <option value="89">89</option>
                                    <option value="91">91</option>
                                    <option value="92">92</option>
                                    <option value="93">93</option>
                                    <option value="94">94</option>
                                    <option value="95">95</option>
                                    <option value="96">96</option>
                                    <option value="97">97</option>
                                    <option value="98">98</option>
                                    <option value="99">99</option>
                                </select>
                            </div>
                        </div>
                        {/** end::DDD*/}
                        
                        {/** begin::Telefone*/}
                        <div className="col-md-9 col-lg-9 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="phone_number">Telefone:</label>
                                <input
                                    placeholder="9 9999-9999"
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="phone_number"
                                    {...formik.getFieldProps("phone_number")}
                                    onChange={(e) => {
                                        formik.setFieldValue("phone_number", phoneMaskWithoutDDD(e.target.value));
                                    }}
                                />
                            </div>
                        </div>
                        {/** end::Telefone*/}
                    </div>

                    <div className="row">
                        {/** begin::Servico*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="carrier_id">Operadora:</label>
                                <select
                                    className="form-control select2 h-auto py-4 px-6"
                                    name="carrier_id"
                                    onChange={(e) => {
                                        formik.setFieldValue("carrier_id", e.target.value);
                                        findPlanValues({
                                            id: e.target.value,
                                            area_code: formik.getFieldProps("phone_ddd").value
                                        });
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.carrier_id}
                                >
                                    <option value="">{loadCarriers}</option>
                                    {carriers.map((v, i) =>
                                        <option key={v.id} value={v.id}>{v.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        {/** end::Servico*/}
                        
                         {/** begin::Servico*/}
                         <div className="col-md-6 col-lg-6 col-xs-12">
                         <div className="form-group fv-plugins-icon-container">
                             <label htmlFor="value">Valor da recarga:</label>
                             <select
                                 className="form-control select2 h-auto py-4 px-6"
                                 name="value"
                                 onChange={(e) => {
                                     formik.setFieldValue("value", e.target.value);
                                 }}
                                 onBlur={formik.handleBlur}
                                 value={formik.values.value}
                             >
                                
                                <option value="">{loadPlans}</option>
                                {plans.plan_values.map((v, i) =>
                                    <option key={v} value={v}>{currency(v)}</option>
                                )}
                             </select>
                         </div>
                     </div>
                     {/** end::Servico*/}

                    </div>

                    <div className="row">
                        <div className="col-md-12 col-md-offset-2">
                            <ButtonCustom
                                layoutProps={layoutProps}
                                type="submit"
                            >
                                Realizar recarga
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </ButtonCustom>
                        </div>
                    </div>
                   
                </form>
            </CardBody>
        </Card>
    </>);
}

export default injectIntl(connect(
    ({ phone_recharge }) => ({ 
        carriers: phone_recharge.carriers,
        loadCarriers: phone_recharge.loadCarriers,
        plans: phone_recharge.plans,
        loadPlans: phone_recharge.loadPlans,
        error_fields: phone_recharge.error_fields 
    }), 
actions)(PhoneRechargeForm));
