// import e from "express";
import React from "react";
import {
  Card,
  CardBody
} from "../../../../partials/controls";
import { Subaside } from "../../../components/subaside/Subaside";
import FavoritesTable from "./table/FavoritesTable";

const FavoritesPage = () => {
  return (
    <div className="row">
      <Subaside />

      <Card className="col-xs-8 col-xl-8 col-md-8">
        <CardBody>
          <h4>Lista de Favoritos</h4>
          <p className="text-muted mt-1">
            Acompanhe seus favoritos
          </p>
          <FavoritesTable />
        </CardBody>
      </Card>
    </div>
    
  );
};

export default FavoritesPage;