import React from 'react';
import { Card, CardBody } from '../../../partials/controls';

export const CardMessage = ( props ) => {
    return(
        <Card>
            <CardBody>
                {props.message}
                {props.children}
            </CardBody>
        </Card>
    )
}