import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../helpers";
import { Link } from "react-router-dom";

export function ButtonColumnFormatter(cellContent, row) {
    return (
        <Link to={`boleto/${row.id}`}>
            <button className="btn btn-default"
            >
                <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}
                >
                </SVG>
            </button>
        </Link>
    );
}
