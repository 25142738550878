import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../components';
import * as fromState from './_redux/actions';
import { cpfMask, onlyNumbers } from '../../../../utils/mask_cpf_cnpj.utils';
import ApiServices from '../../../../../services/ApiServices';
import { ENDPOINT } from '../../../../../services/enums/EndPoint';
import MessageAlert from '../../../../components/alert/MessageAlert';
import _ from 'lodash';

function BasicInformation(props) {
  const { registerBasicInformation, basic } = props;
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [listErrors, setListErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const RegistrationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    email: Yup.string()
      .email('Formata do e-mail errado.')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    emailConfirm: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .oneOf([Yup.ref('email')], intl.formatMessage({id: 'AUTH.VALIDATION.EMAIL_NOT_MATCH'}))
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    cpf: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .cpf(
        intl.formatMessage({
          id: 'FIELD.INVALID.CPF',
        })
      ),
  });

  const verifyInformations = async data => {
    try {
      await ApiServices.post(ENDPOINT.REGISTER_PERSON, data);
    } catch (err) {
      const { field_errors } = err.response.data;
      let listErrors = {};

      if (field_errors.email) {
        listErrors.email = field_errors.email[0];
      }

      if (field_errors.cpf) {
        listErrors.cpf = field_errors.cpf[0];
      }

      return listErrors;
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const nextStep = () => {
    history.push('/cadastro/telefone');
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const initialValues = {
    name: '',
    email: '',
    confirmEmail: '',
    cpf: basic.cpf > 0 ? cpfMask(basic.cpf) : '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      values.cpf = onlyNumbers(values.cpf);

      const response = await verifyInformations({
        cpf: values.cpf,
        email: values.email,
      });

      if (!_.has(response, 'cpf') && !_.has(response, 'email')) {
        setListErrors({});
        setHasErrors(false);
        enableLoading();
        registerBasicInformation(values);
        nextStep();
      } else {
        setListErrors(response);
        setHasErrors(true);
      }
    },
  });

  const ErrorMessage = () => {
    let messageText = '';
    const { cpf, email } = listErrors;

    if (cpf) {
      messageText += `${cpf.message}          `;
    }

    if (email) {
      messageText += `${email.message}`;
    }

    return (
      <MessageAlert
        show={hasErrors}
        title="Opsss... Aconteceu um erro!"
        message={messageText}
        onHide={() => {
          setHasErrors(false);
          setLoading(false);
        }}
      />
    );
  };

  const nameString = text => `${text}`.replace(/\d/g, '');

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <ErrorMessage />

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.PHYSICAL.NAME" />
          </label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'name'
            )}`}
            name="name"
            {...formik.getFieldProps('name')}
            onChange={e => {
              formik.setFieldValue('name', nameString(e.target.value));
            }}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LABEL.EMAIL" />
          </label>
          <input
            autoComplete="none"
            type="email"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Confirm Email */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LABEL.EMAIL_CONFIRM" />
          </label>
          <input
            autoComplete="none"
            type="email"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'emailConfirm'
            )}`}
            name="emailConfirm"
            {...formik.getFieldProps('emailConfirm')}
          />
          {formik.touched.emailConfirm && formik.errors.emailConfirm ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.emailConfirm}</div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Email */}

        {/* begin: CPF */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LABEL.DOCUMENT" />
          </label>
          <input
            maxLength="14"
            type="text"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'cpf'
            )}`}
            name="cpf"
            {...formik.getFieldProps('cpf')}
            onChange={e => {
              formik.setFieldValue('cpf', cpfMask(e.target.value));
            }}
          />
          {formik.touched.cpf && formik.errors.cpf ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.cpf}</div>
            </div>
          ) : null}
        </div>
        {/* end: CPF */}

        <div className="form-group d-flex flex-wrap flex-center">
          <div className="col-md-10">
            <ButtonCustom
              layoutProps={layoutProps}
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
              size="sm"
            >
              <FormattedMessage id="AUTH.REGISTER.CREATE" />
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </ButtonCustom>
          </div>
        </div>
      </form>
    </div>
  );
}

export default connect(
  ({ physicalPerson }) => ({
    basic: physicalPerson.basic,
  }),
  fromState.actions
)(BasicInformation);
