import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../helpers';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../components';
import { Link } from 'react-router-dom';

function DocumentsForCorrection({ hasRedirectToDashboard }) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <div className="card border-0">
      <div className="card-header text-center border-0 mt-5">
        <SVG
          title="Parabéns!"
          src={toAbsoluteUrl('/media/svg/icons/Register/Register-Finished.svg')}
        ></SVG>
      </div>
      <div className="card-body text-center border-0 pt-5">
        <h3>
          Algumas ações são necessárias para que você possa utilizar sua conta
        </h3>

        <h6 className="text-muted pb-5">
          Acesse "Perfil - Meus dados" ou clique no botão abaixo para resolver.
        </h6>

        <Link to="/conta/meus-dados">
          <ButtonCustom
            className="mt-30"
            layoutProps={layoutProps}
            size="sm"
            type="button"
          >
            Meus dados
          </ButtonCustom>
        </Link>
      </div>
    </div>
  );
}

export default connect(
  ({ physicalPerson }) => ({
    physical: physicalPerson.basic,
  }),
  null
)(DocumentsForCorrection);
